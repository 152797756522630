import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Button from '~/modules/coreUI/components/basic/Button';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

import getFormFields from './ChangePasswordFormFields';
import ChangeUserPasswordMutation from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/ChangeUserPasswordMutation';

class ChangePasswordFormForm extends React.Component {
  state = {
    isLoading: false,
    errors: null,
  };

  constructor(props) {
    super(props);
    this.formFields = getFormFields(props.userInfo);
  }

  onSuccess = () => {
    this.props.notifyAlert({
      messageText: i18next.t('accountManagement:Profile.passwordChangedSuccessfully'),
      type: AlertTypes.success,
      opacity: 1,
      toastID: 'ChangePasswordFormForm_success_toast',
    });
    this.setState({ errors: null, isLoading: false });
    this.form.resetForm();
    setTimeout(() => { externalRedirect('/'); }, 1000);
  }

  onError = errors => this.setState({ errors, isLoading: false });

  onLoading = isLoading => this.setState({ isLoading });

  submitForm = () => this.form.submitForm();

  render = () => (
    <Column showHeader={false} width="100%" maxWidth={60} spaceBetween={1.5}>
      {this.state.errors && (
        <PanelAlert type="error" inverted errors={this.state.errors} />
      )}
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={error => this.onError(error)}
        onFormSuccess={response => this.onSuccess(response)}
        onFormLoading={loading => this.onLoading(loading)}
        mutationRoot="update_user_password"
        mutation={ChangeUserPasswordMutation}
        options={{
          fields: this.formFields,
        }}
        getSubmissionVariables={formVariables => ({
          input: {
            ...formVariables,
          },
        })}
      />
      <Row fullWidth rightJustified spaceBetween={2}>
        <Button
          secondary
          loading={this.state.isLoading}
          width="120px"
          onClicked={() => this.submitForm()}
        >
          {i18next.t('accountManagement:Profile.save')}
        </Button>
      </Row>
    </Column>
  );
}

ChangePasswordFormForm.propTypes = {
  notifyAlert: PropTypes.func.isRequired,
  media: PropTypes.shape({
    minTablet: PropTypes.bool,
  }).isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withMedia(withAlertMsg(withUserInfo(ChangePasswordFormForm)));
