import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import LinesEllipsis from 'react-lines-ellipsis';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const CardSubTitle = withDirection(styled(LinesEllipsis)`
  color: ${props => props.theme.colors.labels.subtle};
  font-size: ${props => props.theme.fonts.sizes.small}px;
  ${props => (props.direction === 'rtl'
    && css`
      text-align: right;
  `)}
`);

const SubTitle = ({ itemInfo }) => (
  <CardSubTitle maxLine={2} ellipsis="…" text={itemInfo?.departments[0]?.name} trimRight basedOn="letters" />
);

SubTitle.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default SubTitle;
