const SocialMediaTrans = {
  En: {
    CONNECTWITH: 'Connect With',
    LogInWith: 'Log In With',
    Instagram: 'Instagram',
    GooglePlus: 'Google',
    Twitter: 'Twitter',
    Facebook: 'Facebook',
    Apple: 'Apple',
    SuccessfullyLoggedIn: 'Successfully Logged In',
    ConnectingWithSocialMedia: 'Connecting with social media',
    ToConnectLoginFirst: 'To Connect {{provider}}, Login First!',
    ThereIsNoAccountConnectedWith: 'There Is No Account Connected With {{provider}}!',
    ErrorinConnectingtoTwitter: 'Error in Connecting to Twitter',
    PleaseAllowPopup: 'Please Allow Popup, Then Try Again!',
    Popuphasbeenclosedbyuser: 'Popup has been closed by user',
  },
  Ar: {
    CONNECTWITH: 'ربط حسابك مع',
    LogInWith: 'تسجيل الدخول بـ',
    Instagram: 'انستجرام',
    GooglePlus: 'جوجل',
    Twitter: 'تويتر',
    Facebook: 'فيسبوك',
    Apple: 'ابل',
    SuccessfullyLoggedIn: 'سجل دخولك بنجاح',
    ConnectingWithSocialMedia: 'التوصل معى وسائل التواصل الاجتماعى',
    ToConnectLoginFirst: 'للتوصل ب {{provider}} سجل الدخول اولا',
    ThereIsNoAccountConnectedWith: 'لا يوجد حساب ثوصل ب {{provider}}!',
    ErrorinConnectingtoTwitter: 'خطا فى التوصل معى تويتر',
    PleaseAllowPopup: 'من فضلك اسمح ب النوافز المنبثقه و حاول مجددا',
    Popuphasbeenclosedbyuser: 'النافزه المنبثقه غلقت بواسطه المستخدم',
  },
};
export default SocialMediaTrans;
