/**
 * @flow
 * @relayHash 5f782de399f684314cad78f9c9e006b4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SectionsListQueryVariables = {||};
export type SectionsListQueryResponse = {|
  +sections: $ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
    +sub_title: ?string,
    +target: ?string,
    +products: ?$ReadOnlyArray<?{|
      +avg_rating: ?number,
      +discount: ?number,
      +has_discount: ?boolean,
      +has_offer: ?boolean,
      +hot_deal: ?boolean,
      +id: string,
      +images: $ReadOnlyArray<?string>,
      +min_variant: ?{|
        +slug: ?string,
        +name: string,
        +ref_id: string,
        +root_variant_id: ?string,
        +discount_expire_at: ?any,
        +remaining_usage_percentage: ?number,
        +total_on_hand: ?number,
        +price: number,
        +original_price: number,
      |},
      +rating_count: ?number,
      +ref_id: string,
      +vendor_variants_count: ?number,
      +wish_listed: ?boolean,
    |}>,
    +shops: ?$ReadOnlyArray<?{|
      +avg_rating: ?number,
      +city: ?{|
        +name: ?string
      |},
      +country: ?{|
        +name: ?string
      |},
      +departments: ?$ReadOnlyArray<?{|
        +name: string
      |}>,
      +featured: ?boolean,
      +follow: ?boolean,
      +followed: ?boolean,
      +id: string,
      +logo_url: ?string,
      +name: ?string,
      +rating_count: ?number,
      +ref_id: string,
      +track: ?string,
    |}>,
  |}>
|};
export type SectionsListQuery = {|
  variables: SectionsListQueryVariables,
  response: SectionsListQueryResponse,
|};
*/


/*
query SectionsListQuery {
  sections {
    id
    title
    sub_title
    target
    products {
      avg_rating
      discount
      has_discount
      has_offer
      hot_deal
      id
      images
      min_variant {
        slug
        name
        ref_id
        root_variant_id
        discount_expire_at
        remaining_usage_percentage
        total_on_hand
        price
        original_price
        id
      }
      rating_count
      ref_id
      vendor_variants_count
      wish_listed
    }
    shops {
      avg_rating
      city {
        name
        id
      }
      country {
        name
        id
      }
      departments {
        name
        id
      }
      featured
      follow
      followed
      id
      logo_url
      name
      rating_count
      ref_id
      track
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sub_title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "target",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "images",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "has_discount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "has_offer",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hot_deal",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount_expire_at",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "remaining_usage_percentage",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total_on_hand",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "vendor_variants_count",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "followed",
  "args": null,
  "storageKey": null
},
v23 = [
  v11
],
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "featured",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "follow",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo_url",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "track",
  "args": null,
  "storageKey": null
},
v28 = [
  v11,
  v0
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SectionsListQuery",
  "id": null,
  "text": "query SectionsListQuery {\n  sections {\n    id\n    title\n    sub_title\n    target\n    products {\n      avg_rating\n      discount\n      has_discount\n      has_offer\n      hot_deal\n      id\n      images\n      min_variant {\n        slug\n        name\n        ref_id\n        root_variant_id\n        discount_expire_at\n        remaining_usage_percentage\n        total_on_hand\n        price\n        original_price\n        id\n      }\n      rating_count\n      ref_id\n      vendor_variants_count\n      wish_listed\n    }\n    shops {\n      avg_rating\n      city {\n        name\n        id\n      }\n      country {\n        name\n        id\n      }\n      departments {\n        name\n        id\n      }\n      featured\n      follow\n      followed\n      id\n      logo_url\n      name\n      rating_count\n      ref_id\n      track\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SectionsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sections",
        "storageKey": null,
        "args": null,
        "concreteType": "section",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "products",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              v8,
              v0,
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "min_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  v17,
                  v18
                ]
              },
              v19,
              v12,
              v20,
              v21
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shops",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": [
              v22,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "country",
                "storageKey": null,
                "args": null,
                "concreteType": "Country",
                "plural": false,
                "selections": v23
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "departments",
                "storageKey": null,
                "args": null,
                "concreteType": "TrackDepartment",
                "plural": true,
                "selections": v23
              },
              v24,
              v25,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "city",
                "storageKey": null,
                "args": null,
                "concreteType": "State",
                "plural": false,
                "selections": v23
              },
              v0,
              v26,
              v11,
              v19,
              v12,
              v27
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SectionsListQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sections",
        "storageKey": null,
        "args": null,
        "concreteType": "section",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "products",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              v8,
              v0,
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "min_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  v17,
                  v18,
                  v0
                ]
              },
              v19,
              v12,
              v20,
              v21
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shops",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": [
              v22,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "country",
                "storageKey": null,
                "args": null,
                "concreteType": "Country",
                "plural": false,
                "selections": v28
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "departments",
                "storageKey": null,
                "args": null,
                "concreteType": "TrackDepartment",
                "plural": true,
                "selections": v28
              },
              v24,
              v25,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "city",
                "storageKey": null,
                "args": null,
                "concreteType": "State",
                "plural": false,
                "selections": v28
              },
              v0,
              v26,
              v11,
              v19,
              v12,
              v27
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa34dff5f508a04a4df38bbc9f873a4b';
module.exports = node;
