/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import i18next from 'i18next';
import styled from 'react-emotion';
import { graphql } from 'relay-runtime';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import NotificationsCountLabel from '~/modules/coreUI/components/basic/NotificationsCountLabel';
import DropdownNotifications from '~/modules/ecommerceOrder/containers/notificationAndMessages/notifications/DropdownNotifications';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import MessagesListFragment from '~/modules/ecommerceOrder/containers/notificationAndMessages/messages/messagesQuery/MessagesList';
import MessagesListTab, { MAX_NUMBER_OF_SHOWN_MESSAGES } from './MessagesListTab';
import CustomTabs from '../CustomTabs';
import CustomDropdown from '../CustomDropdown';
import commitSeenMutation from './commitSeenMutation';

import NotificationsIcon from './notificationIcon';

const MessagesAndNotificationWrapper = styled(Column)`
  position: relative;
`;

class NavNotification extends Component {
  state = {
    dropdownOpen: false,
    notificationsList: [],
  };


  showNotificationsMenu = () => {
    const { authenticated, history, environment } = this.props;
    if (authenticated) {
      this.props.refetchMethod();
      if (this.props.media.minDesktop) {
        this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
        if (this.state.dropdownOpen) {
          this.setState({ notificationsList: null });
        } else {
          this.setState({ notificationsList: <DropdownNotifications /> });
        }
      } else {
        externalRedirect(urlFor('getNotificationAndMessagesLink', ['notifications']));
      }
      commitSeenMutation(environment, () => {});
    } else {
      navigateToModal(history, '/accountManagement/login');
    }
  }


  render() {
    const { queryResult, media } = this.props;
    const { dropdownOpen } = this.state;
    const unreadCount = queryResult?.thread_list?.unread_notifications_count;
    return (
      <MessagesAndNotificationWrapper>
        <NotificationsIcon showNotificationsMenu={this.showNotificationsMenu} />
        {dropdownOpen && media.minDesktop && (
          <CustomDropdown
            showMenu={this.showNotificationsMenu}
            dropdownContent={(
              <CustomTabs
                tabs={[{
                  label: (unreadCount > 0 ? (
                    `${i18next.t('ecommerceOrder:notificationsAndMessages.notificationsTabLabel')} (${unreadCount})`
                  ) : (
                    i18next.t('ecommerceOrder:notificationsAndMessages.notificationsTabLabel')
                  )),
                  content: this.state.notificationsList,
                  name: 'notifications',
                }, {
                  label: (
                    <React.Fragment>
                      {i18next.t('ecommerceOrder:notificationsAndMessages.messagesTabLabel')}
                      <NotificationsCountLabel unreadMessagesCount={queryResult?.thread_list?.threads_with_unread_messages_count} />
                    </React.Fragment>
                  ),
                  content: <MessagesListTab threads={queryResult?.thread_list?.threads || []} />,
                  name: 'messages',
                }]}
              />
            )}
          />
        )}
      </MessagesAndNotificationWrapper>
    );
  }
}

const NavNotificationQuery = graphql`
  query NavNotificationQuery($input: ThreadListInput) {
    ...MessagesList_queryResult @arguments(input: $input)
  }
`;


NavNotification.defaultProps = {
  queryResult: {},
  notifications: null,
};

NavNotification.propTypes = {
  queryResult: PropTypes.shape({
    thread_list: PropTypes.shape({
      threads_with_unread_messages_count: PropTypes.number,
    }),
  }),
  media: PropTypes.shape({
    minDesktop: PropTypes.bool,
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  environment: PropTypes.shape({}).isRequired,
  notifications: PropTypes.shape({ unseen_count: PropTypes.any }),
};

export default withRelayEnvironment(withRouter(withUserInfo(withMedia(withRefetchQuery(
  NavNotification,
  NavNotificationQuery,
  MessagesListFragment,
  NavNotification,
  () => ({
    input: {
      page: {
        offset: 0,
        limit: MAX_NUMBER_OF_SHOWN_MESSAGES,
      },
    },
  }),
  null,
  {
    pageInfo: queryResult => _.cloneDeep(queryResult.thread_list.page_info),
  },
  true,
  null,
  { force: true },
)))));
