import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

// import 'firebase/auth';
import 'firebase/database';

import FirebaseContext from './FirebaseContext';

import config from '~/firebase.config';
import 'firebase/firebase-messaging';
import subscribeOrUnsubscribeToTopic from '~/subscribeOrUnsubscribeMethods';

const defaultApp = firebase.initializeApp(config);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.requestPermission()
  .then(() => {
    console.log('success');
    return messaging.getToken();
  }).then((token) => {
    console.log('Token >> ', token);
    subscribeOrUnsubscribeToTopic(token, 'all_users', 'POST'); // subscribe to topic
  }).catch((error) => {
    console.log('error >> ', error);
  });
  console.log('Messaging', messaging);
}
const FirebaseProvider = props => (
  <FirebaseContext.Provider value={{ firebase: defaultApp }}>
    {props.children}
  </FirebaseContext.Provider>
);

FirebaseProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default FirebaseProvider;
