/* eslint-env browser */
/* eslint-disable */
const subscribeOrUnsubscribeToTopic = (token, topic, reqMethod) => {
  const fcmServerKey = 'AAAA-HjHC_M:APA91bEmdV8yvrwheKGuLWpKyt0ZSOxX-SgAI387ltyEfRpLhphFDDzSw-gcQh9AWF0lfaX-BtSYHW14LpaJVKxmsHrQrMxzXPUJL5VPRFx24Wdy5naTKIhCgqrje8l5QyT5XZVBbnHb';
  const method = reqMethod;
  fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
    method: method,
    headers: new Headers({ Authorization: 'key=' + fcmServerKey }),
  }).then((response) => {
    if (response.status < 200 || response.status >= 400) {
      console.log('Error subscribing or unsubscribing to/from topic: ' + response.status + ' - ' + response.text());
    }
    if (method === 'POST') {
      console.log('Subscribed to "' + topic + '"');
    }else{
      console.log('unsubscribed from "' + topic + '"');
    }
  }).catch((error) => {
    console.error(error);
  });
}

export default subscribeOrUnsubscribeToTopic;