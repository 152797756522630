/* eslint-disable camelcase,jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'react-emotion';
import Shiitake from 'shiitake';
import changeCase from 'change-case';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import { showDate } from './panelUtils';

import Header from './header';
import Body from './body';
import Footer from './footer';
import OrderSteps from '../OrderSteps';
import urlFor from '../../../../ecommerceCore/utils/UrlConcatination';

const notesWrapper = styled(Shiitake)`
  padding-bottom:10px
`;


const OrderPanel = props => (
  <Panel
    header={(
      <Header
        orderNumberLabel={(
          <Label lg emphasized>
            {i18next.t('ecommerceOrder:order.orderNo')}
          </Label>
        )}
        orderNumberValue={(
          <Label lg important semiBold>
            {props.order.number}
          </Label>
        )}
        orderDate={(
          <Fragment>
            <Label sm>{i18next.t('ecommerceOrder:order.orderDate')}</Label>
            <Spacer size={1} />
            <Label sm important>
              {moment(props.order.created_at).format('DD MMM YYYY')}
            </Label>
          </Fragment>
        )}
        orderStatus={(
          <Fragment>
            <Label sm>{i18next.t('ecommerceOrder:order.status')}</Label>
            <Spacer size={1} />
            <Label lg primary semiBold>
              {props.direction === 'rtl' ? props.order.shipment_state : changeCase.titleCase(props.order.shipment_state)}
            </Label>
          </Fragment>
        )}
        orderSteps={<OrderSteps progressBar={props.order.progress_bar} />}
      />
    )}
    body={(
      <Body
        {...props.order}
        collapsed={props.collapsed}
      />
    )}
    footer={(
      <Footer
        {...props.order}
        orderDeliveryDate={
          showDate(props.order) ? (
            <Fragment>
              <Label sm>
                {props.order.product_type === 'Service' ? i18next.t('ecommerceOrder:order.appointmentDate') : i18next.t('ecommerceOrder:order.deliveryDate')}
              </Label>
              <Spacer size={1} />
              <Label sm important>
                {props.order.delivery_date}
              </Label>
            </Fragment>
          ) : null
        }
        orderTrackingNumber={
          (props.order.product_type === 'Good' && props.order.tracking_number) ? (
            <Fragment>
              <Label sm>{i18next.t('ecommerceOrder:order.trackingNo')}</Label>
              <Spacer size={1} />
              <Label sm important>{props.order.tracking_number}</Label>
            </Fragment>
          ) : null
        }
        refundOrderButton={
          props.order.product_type === 'Good' && props.order.eligible_refund ? (
            <ExternalLink url={`/${props.order.number}/ro`}>
              <Label md nowrap>
                <i className="el-icon el-icon-refund" />
                {' '}
                {i18next.t('ecommerceOrder:order.refundOrder')}
              </Label>
            </ExternalLink>
          ) : null
        }
        orderDetailsButton={(
          <ExternalLink url={urlFor('getOrderDetailsLink', [props.order.number])}>
            <Label md secondary nowrap>
              <i className="el-icon el-icon-orders" />
              {' '}
              {i18next.t('ecommerceOrder:order.orderDetails')}
            </Label>
          </ExternalLink>
        )}
        orderCancelButton={
          props.order.product_type === 'Service' && props.order.eligible_refund ? (
            <ExternalLink url={urlFor('getCancelOrderLink', [props.order.number])}>
              <Label md nowrap>
                <i className="el-icon el-icon-cancel" />
                {' '}
                {i18next.t('ecommerceOrder:order.cancelOrder')}
              </Label>
            </ExternalLink>
          ) : null
        }
        orderNote={props.order.admin_rejection_note ? (
          <Row topAligned spaceBetween={1}>
            <Label md>{i18next.t('ecommerceOrder:order.notes')}</Label>
            <Label md>
              <notesWrapper lines={2} throttleRate={200}>
                {props.order.admin_rejection_note}
              </notesWrapper>
            </Label>
          </Row>
        ) : null}
      />
)}
  />
);

OrderPanel.defaultProps = {
  collapsed: true,
};

OrderPanel.propTypes = {
  order: PropTypes.shape({
    number: PropTypes.string,
    created_at: PropTypes.string,
    shipment_state: PropTypes.string,
    product_type: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  collapsed: PropTypes.bool,
};

export default withDirection(OrderPanel);
