/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';

const NotConnected = () => (
  <Column spaceBetween={1.5}>
    <Spacer size={2.5} />
    <Label lg>
      {i18next.t('ecommerceCoreUI:WrongPages.errorCodeText')}
    </Label>
    <Label semiBold important lg>
    500
    </Label>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="371.103" height="267.6" viewBox="0 0 371.103 267.6">
        <g id="cable" transform="translate(-465 -299)">
          <g id="Group_10" className="cls-1" data-name="Group 10" transform="translate(396.86 212.093)" style={{ opacity: 0.3 }} >
            <path id="Path_43" d="M268.771 112.855c-39.138 12.5-163.11-49.122-153.408 35.044 1.517 12.515 9.577 30.192 2.77 41.876-4.933 8.75-13.975 13.877-22.708 17.279-22.814 9.275-31.309 26.682-25.531 51.526 15.454 66.945 99.581 51.069 143.745 31.219 31.408-14.772 68.45-17.309 100.972-4.813 25.48 10.175 41.363 31.52 71.267 25.267 26.52-5.322 30.522-30.36 22.778-53.668-4.4-14.42-7.558-20.513 1.168-34.432 7.354-13.824 20.3-24.3 21.293-40.494.218-17.541-7.843-35.217-23.441-41.861-12.763-5.435-24.331-3.241-36.956-6.583-28.083-7.892-46.13-51.525-80.968-36.519-8.687 4.1-13.574 13.549-23.039 16.3" className="cls-2" data-name="Path 43" style={{ fill: '#33a8ff', opacity: 0.22 }} />
          </g>
          <g id="Group_122" data-name="Group 122" transform="translate(555.001 375.896)">
            <g id="Path_281" className="cls-8" data-name="Path 281" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} >
              <path d="M5.752 296.09c-.669 0-1.297-.26-1.769-.733a2.486 2.486 0 0 1-.734-1.77c0-.668.26-1.296.733-1.769l41.712-41.712 2.298-2.298-2.298-2.298-10.987-10.987c-8.227-8.228-8.227-21.615 0-29.843l25.225-25.225 60.245 59.66-25.518 25.518a20.963 20.963 0 0 1-14.921 6.18 20.963 20.963 0 0 1-14.922-6.18L53.83 253.646l-2.298-2.299-2.298 2.299-41.712 41.71a2.484 2.484 0 0 1-1.77.734z" className="cls-9" style={{ stroke: 'none' }} transform="translate(0 -108.636)"/>
              <path d="M59.943 184.04l-22.938 22.938c-6.96 6.96-6.96 18.286 0 25.247l10.987 10.987 4.596 4.596 3.54 3.54 10.986 10.987a17.734 17.734 0 0 0 12.624 5.228c4.769 0 9.252-1.857 12.623-5.229l23.208-23.208-55.626-55.086m-.023-9.17l64.864 64.234-27.827 27.827a24.192 24.192 0 0 1-17.22 7.132 24.192 24.192 0 0 1-17.219-7.132l-10.987-10.987-41.712 41.71a5.732 5.732 0 0 1-4.067 1.686 5.751 5.751 0 0 1-4.067-9.82l41.71-41.712-10.986-10.987c-9.495-9.495-9.495-24.944 0-34.44L59.92 174.87z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(0 -108.636)"/>
            </g>
            <g id="Path_282" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 282">
              <path d="M253.7 203.765l-43.99-43.561 3.927-3.928 43.99 43.562-3.928 3.927z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-128.919 -94.067)"/>
              <path d="M213.626 151.691l48.608 48.136-8.523 8.523-48.608-48.135 8.523-8.524z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-128.919 -94.067)"/>
            </g>
            <g id="Path_283" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 283">
              <path d="M298.548 93.04L254.56 49.477l4.158-4.158a20.961 20.961 0 0 1 14.921-6.18 20.962 20.962 0 0 1 14.921 6.18l2.9 2.9 2.297 2.297 2.298-2.298 42.201-42.201a2.486 2.486 0 0 1 1.77-.733c.668 0 1.297.26 1.77.733.472.473.733 1.101.733 1.77 0 .668-.26 1.296-.733 1.768l-42.202 42.202-2.298 2.298 2.298 2.298 2.899 2.899a20.962 20.962 0 0 1 6.18 14.921 20.962 20.962 0 0 1-6.18 14.922l-3.944 3.943z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-157.109)"/>
              <path d="M298.537 88.454l1.657-1.657a17.734 17.734 0 0 0 5.229-12.623c0-4.769-1.857-9.252-5.23-12.623l-2.898-2.899-3.539-3.539-4.596-4.596-2.899-2.899a17.734 17.734 0 0 0-12.623-5.228 17.733 17.733 0 0 0-12.623 5.228l-1.848 1.848 39.37 38.988m.022 9.17l-48.607-48.135 6.467-6.467c4.599-4.6 10.714-7.132 17.219-7.132 6.505 0 12.62 2.533 17.22 7.132l2.898 2.899L335.957 3.72a5.735 5.735 0 0 1 4.068-1.685 5.752 5.752 0 0 1 4.067 9.82l-42.2 42.201 2.898 2.899c4.6 4.599 7.133 10.714 7.133 17.22 0 6.504-2.533 12.62-7.133 17.219l-6.23 6.23z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-157.109)"/>
            </g>
            <g id="Path_284" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 284">
              <path d="M32.317 180.248H11.443a2.505 2.505 0 0 1-2.503-2.502 2.505 2.505 0 0 1 2.503-2.503h20.874a2.505 2.505 0 0 1 2.503 2.503 2.505 2.505 0 0 1-2.503 2.502z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-3.577 -106.828)"/>
              <path d="M32.317 183.498H11.443a5.753 5.753 0 0 1 0-11.505h20.874a5.753 5.753 0 0 1 0 11.505z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-3.577 -106.828)"/>
            </g>
            <g id="Path_285" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 285">
              <path d="M172.615 39.951a2.505 2.505 0 0 1-2.503-2.502V16.574a2.505 2.505 0 0 1 2.503-2.503 2.505 2.505 0 0 1 2.502 2.503v20.875a2.505 2.505 0 0 1-2.502 2.502z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-104.882 -5.522)"/>
              <path d="M172.615 43.201a5.752 5.752 0 0 1-5.753-5.752V16.574a5.753 5.753 0 0 1 11.505 0v20.875a5.753 5.753 0 0 1-5.752 5.752z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-104.882 -5.522)"/>
            </g>
            <g id="Path_286" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 286">
              <path d="M94.156 101.791c-.668 0-1.297-.26-1.77-.733L77.039 85.711a2.487 2.487 0 0 1-.733-1.77c0-.669.26-1.297.733-1.77a2.487 2.487 0 0 1 1.77-.732c.669 0 1.297.26 1.77.732L95.924 97.52c.473.473.733 1.102.733 1.77 0 .668-.26 1.297-.732 1.77a2.485 2.485 0 0 1-1.77.732z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-45.92 -47.867)"/>
              <path d="M94.156 105.041a5.734 5.734 0 0 1-4.067-1.684L74.74 88.009a5.752 5.752 0 1 1 8.135-8.136l15.348 15.348a5.752 5.752 0 0 1-4.067 9.82z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-45.92 -47.867)"/>
            </g>
            <g id="Path_287" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 287">
              <path d="M325.606 448.55a2.505 2.505 0 0 1-2.503-2.503v-20.874a2.505 2.505 0 0 1 2.503-2.503 2.505 2.505 0 0 1 2.502 2.503v20.874a2.505 2.505 0 0 1-2.502 2.503z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-201.045 -262.349)"/>
              <path d="M325.606 419.42a5.753 5.753 0 0 1 5.752 5.753v20.874a5.753 5.753 0 0 1-11.505 0v-20.874a5.753 5.753 0 0 1 5.753-5.753z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-201.045 -262.349)"/>
            </g>
            <g id="Path_288" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 288">
              <path d="M451.454 322.702h-20.875a2.505 2.505 0 0 1-2.503-2.502 2.505 2.505 0 0 1 2.503-2.503h20.875a2.505 2.505 0 0 1 2.502 2.503 2.505 2.505 0 0 1-2.502 2.502z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-267.027 -196.368)"/>
              <path d="M430.579 314.447h20.875a5.753 5.753 0 0 1 0 11.505h-20.875a5.753 5.753 0 0 1 0-11.505z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-267.027 -196.368)"/>
            </g>
            <g id="Path_289" className="cls-8" style={{ fill: '#e5e5e5', strokeLinecap: 'round' }} data-name="Path 289">
              <path d="M393.44 390.536c-.668 0-1.296-.26-1.769-.734l-15.348-15.347a2.486 2.486 0 0 1-.733-1.77c0-.668.26-1.297.733-1.77a2.487 2.487 0 0 1 1.77-.732c.668 0 1.297.26 1.77.732l15.347 15.349c.472.472.733 1.1.733 1.77 0 .667-.26 1.296-.733 1.768a2.485 2.485 0 0 1-1.77.734z" className="cls-9" style={{ stroke: 'none' }} transform="translate(-234.036 -229.358)"/>
              <path d="M378.093 366.933c1.472 0 2.944.561 4.067 1.684l15.348 15.349a5.752 5.752 0 1 1-8.135 8.135l-15.348-15.348a5.753 5.753 0 0 1 4.068-9.82z" className="cls-10" style={{ stroke: 'none', fill: '#999' }} transform="translate(-234.036 -229.358)"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <Label semiBold important xxl>
      {i18next.t('ecommerceCoreUI:WrongPages.oops')}
    </Label>
    <Label lg>
      {i18next.t('ecommerceCoreUI:WrongPages.serverErrorText')}
    </Label>
  </Column>
);


export default withRouter(NotConnected);
