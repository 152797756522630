// TODO: Needs to be Added to ENV or fetched from BackEnd for Security
export default function getSocialProviderCredentials(socialMediaName) {
  const socialMediaConfiguration = {
    Facebook: {
      provider: 'facebook',
      appId: '278492899606854', // process.env.FACEBOOK_CLIENT_ID,
    },
    Twitter: {
      provider: 'twitter',
      appId: '4689862', // process.env.TWITTER_CLIENT_ID,
    },
    'Google Plus': {
      provider: 'google',
      appId: '981263291613-tsq2ocjggn08hbm1omfviojob1f82n08.apps.googleusercontent.com', // process.env.GOOGLE_CLIENT_ID,
    },
  };

  return socialMediaConfiguration[socialMediaName];
}

function getBaseURL() {
  const { protocol, hostname, port } = window.location;
  const baseURL = `${protocol}//${hostname}${port ? `:${port}` : ''}/`;

  return baseURL;
}

export function getRedirectURL() {
  const baseURL = getBaseURL();

  const redirectURL = `${baseURL}__modal/accountManagement/loginWithSocialMedia`;
  return redirectURL;
}

export function getTwitteroAuthConfirmUrl() {
  const baseURL = getBaseURL();

  const requestTokenURL = `${baseURL}__modal/accountManagement/callback/`;
  return requestTokenURL;
}
