import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';

import SearchDropDown from './SearchDropDown';

export const PRODUCST_QUICK_SEARCH_DROPDOWN_KEY = 'products_quick_search_dropdown';

const getListArray = queryResult => (queryResult
  ? queryResult.variants_autocomplete_search.result.map(item => ({
    id: item.id,
    slug: item.slug,
    name: item.name,
    image: item.images[0],
    price: item.display_price,
    vendor_variants_count: item.vendor_variants_count,
    min_variant: item.min_variant,
  }))
  : []);

const DropdownProducts = ({
  queryResult, refetchMethod, isLoading, searchString,
}) => (
  <SearchDropDown
    isLoading={isLoading}
    search={(searchText) => {
      if (!(searchText.includes('.') || searchText.includes('%'))) {
      if (searchText !== '') {
        refetchMethod(
          PRODUCST_QUICK_SEARCH_DROPDOWN_KEY,
          { freetext_search: searchText },
          '^.input',
        );
      }
}
    }}
    listResult={isLoading ? [] : getListArray(queryResult)}
    itemsCount={isLoading ? 0 : queryResult.variants_autocomplete_search.page_info.items_count}
    iconClick={val => externalRedirect(urlFor('getQuickSearchLink', [val]))}
    onEnter={item => (item.slug
      ? externalRedirect(urlFor('getProductDetailsLink', [item.slug, item.vendor_variants_count, item.min_variant?.vendor]))
      : externalRedirect(urlFor('getQuickSearchLink', [item])))
    }
    onItemClick={item => externalRedirect(urlFor('getProductDetailsLink', [item.slug, item.vendor_variants_count, item.min_variant?.vendor]))}
    searchString={searchString}
    onButtonClick={val => externalRedirect(urlFor('getQuickSearchLink', [val]))}
  />
);

DropdownProducts.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
}.isRequired;

const fragment = {
  queryResult: graphql`
    fragment DropdownProducts_queryResult on Query
      @argumentDefinitions(input: { type: "VariantsSearchInput" }) {
      variants_autocomplete_search(input: $input) {
        result {
          images
          name
          display_price
          id
          slug
          vendor_variants_count
          min_variant{
            vendor{
              name
              ref_id
            }
          }
        }
        page_info {
          items_count
        }
      }
    }
  `,
};

const query = graphql`
  query DropdownProductsQuery($input: VariantsSearchInput) {
    ...DropdownProducts_queryResult @arguments(input: $input)
  }
`;

export default withRefetchQuery(DropdownProducts, query, fragment, DropdownProducts, props => ({
  input: {
    freetext_search: props.searchString,
    page: {
      limit: 4,
    },
  },
}));
