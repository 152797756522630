import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';

import { List, Header } from 'semantic-ui-react';
import getFirstNChilren from '~/modules/core/utils/jsHelpers/getFirstNChildrenInArray';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';

import TaxonLink from './TaxonLink';

const SubDepartmentsContainer = styled.div`
  width: 25%;
  min-height: 172px;
  max-height: 172px;
  .ui.header {
    color: ${props => props.theme.colors.labels.important}!important;
    font-size: ${props => props.theme.fonts.sizes.small}px;
    opacity: 0.7;
  }
  .ui.bulleted.list,
  ul.ui.list {
    margin-bottom: 5px;
  }
  a,
  .ui.bulleted.list > .item,
  .ui.bulleted.list .list > .item,
  .ul.ui.list li {
    color: ${props => props.theme.colors.labels.important};
    font-size: ${props => props.theme.fonts.sizes.xSmall}px;
    font-weight: 300;
    text-transform: capitalize;
    opacity: 0.7;
  }
  .ui.bulleted.list > .item a:hover {
    opacity: 1;
  }
`;

const ViewAllLink = styled(ExternalLink)`
  color: ${props => props.theme.colors.primary} !important;
  font-size: ${props => props.theme.fonts.sizes.xSmall}px;
  text-transform: capitalize;
  text-decoration: underline;
`;

const SubDepartmentsHeader = styled(Header)`
cursor:default;
`;
const MAX_LINKS_TO_SHOW = 6;

const DepartmentPanLinkListGroup = ({
  taxonList, groupName, departmentId, departmentName, hideDepartments,
}) => {
  const allLinks = (taxons) => {
    const firstNChildren = getFirstNChilren(taxons, MAX_LINKS_TO_SHOW);
    return firstNChildren.map(taxonInfo => (
      <TaxonLink
        taxonInfo={taxonInfo}
        // eslint-disable-next-line no-underscore-dangle
        key={taxonInfo.__id}
        departmentId={departmentId}
        departmentName={departmentName}
      />
    ));
  };

  const handleViewAllClick = () => {
    hideDepartments();
    externalRedirect(urlFor('getProductTracksLink', [
      { ref_id: departmentId, name: departmentName }, null, null, groupName,
    ]));
  };

  if (!taxonList || taxonList.length === 0) {
    return null;
  }

  return (
    <SubDepartmentsContainer>
      <SubDepartmentsHeader as="h4">{groupName}</SubDepartmentsHeader>
      <List bulleted>
        {allLinks(taxonList)}
      </List>
      {
        taxonList && taxonList.length > MAX_LINKS_TO_SHOW ? (
          <ViewAllLink onClick={() => handleViewAllClick()}>
            {i18next.t('ecommerceCoreUI:departmentInfo.viewAllLabel')}
          </ViewAllLink>
        ) : (
          null
        )
      }
    </SubDepartmentsContainer>
  );
};


DepartmentPanLinkListGroup.defaultProps = {
  groupName: null,
  departmentId: null,
  departmentName: null,
};

DepartmentPanLinkListGroup.propTypes = {
  taxonList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupName: PropTypes.string,
  departmentId: PropTypes.string,
  departmentName: PropTypes.string,
  hideDepartments: PropTypes.func.isRequired,
};

export default DepartmentPanLinkListGroup;
