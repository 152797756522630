/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
/* this file is taken from https://www.npmjs.com/package/react-dropdown-autocomplete */

const isCustomColor = (color) => {
  const c = color.trim();

  if (c[0] === '#' || c.indexOf('rgb(') > -1 || c.indexOf('rgba(') > -1) return true;

  return false;
};
const DropdownWrapper = withDirection(styled.div`
  .autocomplete-field {
    position: relative;
  }
  .autocomplete-field .input-field {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .autocomplete-field input[type="text"] {
    width: 100%;
  }
  .autocomplete-field.has-icon input[type="text"] {
    padding-right: 40px;
  }
  .autocomplete-field .autocomplete-list {
    position: absolute;
    top: 43px;
    width: 100%;
    background: white;
    overflow: auto;
    height: 0;
    z-index: 9;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }
  .autocomplete-field .autocomplete-list.show {
    border: 1px solid #d2d6de;
    border-top: none;
    height: unset;
    max-height: 214px;
  }
  .autocomplete-field .autocomplete-list > div {
    padding: 6px 12px;
    background: white;
    border-bottom: 1px solid #d2d6de;
    cursor: pointer;
    outline: none;
  }
  .autocomplete-field .autocomplete-list > div:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .autocomplete-field .autocomplete-list > div:last-child {
    border: none;
  }
  .autocomplete-field .autocomplete-list > div.isFocus {
    background: rgba(0, 0, 0, 0.2);
  }
  .autocomplete-field .icon-search {
    position: absolute;
    ${props => (props.direction === 'rtl'
    ? css`
        left: 0;
      `
    : css`
        right: 0;
    `)}
    top: 50%;
    height: 32px;
    width: 32px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.3em;
    padding: .2em;
    text-align: center;
    outline: none;
  }
`);

class ReactDropdownAutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.open || false,
      list: [],
      isFocus: -1,
      editField: props.value || '',
    };

    this.renderMenu = this.renderMenu.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectOption = this.handleSelectOption.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
    this.handleIconClick = this.handleIconClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const newPropWithoutspecialChars = nextProps.value.replace(/%|\./g, '');
    this.setState({
      editField: newPropWithoutspecialChars || '',
    });
  }

  componentWillUnmount() {
    clearTimeout(this.inputBlurTimer);
  }

  isOpen() {
    return this.state.isOpen;
  }

  filterList() {
    const { data, getItemValue } = this.props;
    const { value } = this.inputField || {};
    let list = [];

    if (value && value.trim()) {
      list = data.filter((d) => {
        const itemValue = getItemValue(d).toLowerCase();
        return itemValue.indexOf(value.trim().toLowerCase()) > -1;
      });
    }

    return list;
  }

  handleInputChange(e) {
    const { target } = e;
    const { value } = target;
    const { onChange } = this.props;
    onChange(value);
    const list = this.filterList();

    this.setState({
      list,
      isOpen: list.length > 0,
      isFocus: -1,
      editField: value,
    });
  }

  handleSelectOption(data) {
    const { list } = this.state;
    const { onChange, getItemValue } = this.props;
    const value = getItemValue(data);

    onChange(value);

    this.setState({
      editField: value,
      list: [],
      isFocus: list.indexOf(data),
      isOpen: false,
    });
  }

  handleInputFocus() {
    const list = this.filterList();

    this.setState({
      list,
      isFocus: -1,
      isOpen: list.length > 0,
    });
  }

  handleInputBlur() {
    this.inputBlurTimer = setTimeout(() => {
      this.setState({
        list: [],
        isOpen: false,
      });
    }, 200);
  }

  handleIconClick() {
    this.props.iconClick();
  }

  handleInputKeyUp(e) {
    const { isFocus } = this.state;
    const { keyCode } = e;

    const list = this.filterList();

    e.stopPropagation();
    e.preventDefault();

    if (keyCode === 13) {
      // Enter
      this.Enter(e);
    } else if (keyCode === 38) {
      // Up
      if (isFocus >= 0) {
        this.setState({
          move: 'up',
          isFocus: isFocus - 1,
        });
      }
    } else if (keyCode === 40) {
      // Down
      if (isFocus < list.length - 2) {
        this.setState({
          move: 'down',
          isFocus: isFocus + 1,
        });
      }
    }
  }

  Enter() {
    const { isFocus, editField } = this.state;
    const { onChange, getItemValue, onEnter } = this.props;
    const filterList = this.filterList();
    const data = filterList[isFocus];

    const value = (data && getItemValue(data)) || editField;

    onChange(value);

    this.setState({
      editField: value,
      list: [],
      isOpen: false,
    });

    if (data !== undefined) {
      onEnter(data);
    } else {
      onEnter(value);
    }
  }

  scrollListContainer() {
    const { isFocus, move } = this.state;
    if (isFocus < 0) {
      return;
    }
    const c = this.listContainer;
    if (c) {
      if (c.children[0]) {
        const nh = c.offsetHeight;
        const ch = c.children[0].offsetHeight;
        const st = c.scrollTop;

        if (move === 'down') {
          const moveBottom = (isFocus + 1) * ch;

          if (moveBottom - st > nh) {
            c.scrollTo(0, moveBottom - nh);
          }
        } else if (move === 'up') {
          const moveTop = isFocus * ch;
          if (moveTop < st) {
            c.scrollTo(0, moveTop);
          }
        }
      }
    }
  }

  renderMenu() {
    const { renderItem } = this.props;
    const { isFocus } = this.state;

    const menus = this.filterList().map((data, i) => {
      const item = renderItem(data);

      return React.cloneElement(item, {
        key: data.id || data.name,
        className: i === isFocus ? 'isFocus' : '',
        onClick: () => this.handleSelectOption(data),
      });
    });

    setTimeout(() => {
      this.scrollListContainer();
    }, 10);

    return menus;
  }

  render() {
    const { isOpen, editField } = this.state;
    const {
      className, id, name, placeholder, icon, iconColor,
    } = this.props;
    const MIN_LENGTH_OF_VALUE = 1;
    return (
      <DropdownWrapper>
        <div className={`autocomplete-field ${icon ? 'has-icon' : ''}`}>
          <div className="input-field">
            <input
              type="text"
              className={className}
              id={id}
              name={name}
              autoComplete="off"
              placeholder={placeholder}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              onChange={this.handleInputChange}
              onKeyUp={this.handleInputKeyUp}
              value={editField}
              ref={(input) => {
                this.inputField = input;
              }}
            />
            {icon && (
              <div role="button" tabIndex="-1" className="icon-search" onClick={this.handleIconClick}>
                <i
                  className={`el-icon el-icon-search ${!isCustomColor(iconColor) ? iconColor : ''}`}
                  style={{ color: `${isCustomColor(iconColor) ? iconColor : ''}` }}
                />
              </div>
            )}
          </div>
          <div
            className={`autocomplete-list
          ${isOpen && editField.length > MIN_LENGTH_OF_VALUE ? 'show' : ''}`}
            ref={(item) => {
              this.listContainer = item;
            }}
          >
            {this.renderMenu()}
          </div>
        </div>
      </DropdownWrapper>
    );
  }
}

ReactDropdownAutoComplete.defaultProps = {
  getItemValue: () => {},
  /* eslint-disable react/no-this-in-sfc */
  renderItem: item => (
    <div role="button" tabIndex="-1" key={item.id} onClick={() => this.handleSelectOption(item)}>
      {this.props.getItemValue(item)}
    </div>
  ),
  /* eslint-enable react/no-this-in-sfc */
  className: '',
  id: '',
  name: '',
  icon: '',
  iconColor: '',
  placeholder: '',
  data: [],
  open: false,
  onChange: () => {},
  iconClick: () => {},
  onEnter: () => {},
  value: '',
};

ReactDropdownAutoComplete.propTypes = {
  renderItem: PropTypes.func,
  getItemValue: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  iconClick: PropTypes.func,
  onEnter: PropTypes.func,
  value: PropTypes.string,
};

export default ReactDropdownAutoComplete;
