/* eslint-disable camelcase */

import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import WishButton from '~/modules/ecommerceCoreUI/components/productDetails/vendor/buttons/WishButton';
import CompareButton from '~/modules/ecommerceProducts/components/compare/CompareButton';

const CardActionsContainer = withMedia(withDirection(styled.div`
  display: flex;
  i {
    font-size: ${props => (props.media.xsmall ? 30 : 37)}px;
    ${props => props.size === 'small' && css`
      font-size: 25px;
    `}
  }
`));

const Actions = ({
  itemInfo,
  isVertical,
  media,
}) => (
  <CardActionsContainer size={media.xsmall && !isVertical ? 'small' : undefined} className="assets">
    {!itemInfo?.has_offer && (
      <React.Fragment>
        <CompareButton variantID={itemInfo?.min_variant?.root_variant_id || itemInfo?.min_variant?.ref_id || itemInfo?.ref_id} />
        <Spacer size={[0.5, 1]} />
      </React.Fragment>
    )}
    <WishButton wishListed={itemInfo?.root_variant?.wish_listed || itemInfo?.wish_listed} variantId={itemInfo?.root_variant?.ref_id || itemInfo?.min_variant?.ref_id || itemInfo?.ref_id} />
  </CardActionsContainer>
);

Actions.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({}).isRequired,
  isVertical: PropTypes.bool.isRequired,
};

export default withMedia(Actions);
