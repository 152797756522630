/* eslint-disable camelcase */
import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { Container } from 'semantic-ui-react';
import Media from 'react-media';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import AppImage from '~/modules/ecommerceCoreUI/components/footer/AppImage';
import FooterLinks from '~/modules/ecommerceCoreUI/components/footer/FooterLinks';
import SocialMedia from '~/modules/ecommerceCoreUI/components/header/headerActions/SocialMedia';
import { mediaQueryMax, cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Tracks from '~/modules/ecommerceCoreUI/components/Tracks';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

import DownloadApp from './DownloadApp';
import Payment from './Payment';
import SocialNetworkQuery from './SocialNetworkQuery';

const linkList2 = [
  { key: 'ecommerceCoreUI:Footer.termsConditions', url: urlFor('getStaticPageLink', ['terms_conditions']) },
  { key: 'ecommerceCoreUI:Footer.privacyPolicy', url: urlFor('getStaticPageLink', ['privacy_policy']) },
  { key: 'ecommerceCoreUI:Footer.return', url: urlFor('getStaticPageLink', ['return_and_refund_condition']) },
  { key: 'ecommerceCoreUI:Footer.faqs', url: urlFor('getStaticPageLink', ['support']) },
];
const linkList3 = [
  { key: 'ecommerceCoreUI:Footer.about', url: urlFor('getStaticPageLink', ['about_us']) },
  { key: 'ecommerceCoreUI:Footer.contact', url: urlFor('getStaticPageLink', ['contact_us']) },
  { key: 'ecommerceCoreUI:Footer.ask', url: urlFor('getAskAykLink') },
  { key: 'ecommerceCoreUI:Footer.join', url: urlFor('getJoinBusinessLink') },
];

const FooterWrapper = styled.div`
  background: #faf9f9;
  padding: 40px 0;
  ${cssMediaMax.tablet`
    padding: 20px 0;
  `} .container {
    position: relative;
    display: flex;
    ${cssMediaMax.tablet`
      flex-direction: column;
    `};
  }
`;
const ImageWrapper = withDirection(styled(AppImage)`
  position: absolute;
  width: ${props => 22.6 * props.theme.new.spacer}px;
  height: ${props => 44 * props.theme.new.spacer}px;
  ${props => (props.direction === 'rtl'
    ? css`
      left: 0;
    `
    : css`
      right: 0;
    `)};
  bottom: -30px;
  object-fit: cover;
`);

const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ul {
    width: 33.3%;
  }
`;

const FooterDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  ${cssMediaMax.xsmall`
    width: 100%;
  `};
`;
const TabletFooterDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${cssMediaMax.xsmall`
    width: 100%;
  `};
`;
const DownloadAppItem = withDirection(styled(DownloadApp)`
  width: 35%;
  ${cssMediaMax.tablet`
    align-items: flex-start;
    width: 100%;
  `} p {
    width: 50%;
    ${props => cssMediaMax.tablet`
      width: 100%;
      ${props.direction === 'rtl'
    ? css`
            text-align: right;
          `
    : css`
            text-align: left;
      `}
    `};
  }
`);
const SocialMediaItems = styled(SocialMedia)`
  ${cssMediaMax.tablet`
      i {
        font-size: 28px;
      }
    `};
`;
const FooterLinksContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  ul {
    width: 33.3%;
  }
`;
const VerticalSection = styled.div`
  display: flex;
  flex-direction: row;
  ${cssMediaMax.xsmall`
     flex-direction: column;
  `};
`;
const renderForDesktop = queryResult => (
  <FooterWrapper>
    <Container>
      <FooterDetails>
        <FooterLinksWrapper>
          <div>
            <FooterLinks links={Tracks} />
            <Spacer size={1} />
            <SocialMediaItems />
          </div>
          <div>
            <FooterLinks links={linkList2} />
            <Spacer size={4.2} />
            <Payment />
          </div>
          <FooterLinks links={linkList3} />
        </FooterLinksWrapper>
      </FooterDetails>
      <DownloadAppItem
        androidLink={queryResult?.android_application}
        iosLink={queryResult?.ios_application}
      />
      <ImageWrapper footerImage={queryResult?.mobile_app_image?.url_original} />
    </Container>
  </FooterWrapper>
);

const renderForMobile = queryResult => (
  <FooterWrapper>
    <Container>
      <SocialMediaItems title={i18next.t('ecommerceCoreUI:Footer.follow')} />
      <Spacer size={2.5} />
      <DownloadAppItem
        androidLink={queryResult?.android_application}
        iosLink={queryResult?.ios_application}
      />
      <Spacer size={2.5} />
      <TabletFooterDetails>
        <FooterLinksContainer>
          <VerticalSection>
            <FooterLinks links={Tracks} />
            <FooterLinks links={linkList2} />
          </VerticalSection>
          <FooterLinks links={linkList3} />
        </FooterLinksContainer>
        <Payment />
      </TabletFooterDetails>
    </Container>
  </FooterWrapper>
);

const FooterContainer = ({ queryResult }) => (
  <Media query={mediaQueryMax('tablet')}>
    {matches => (matches ? renderForMobile(queryResult?.social_networks) : renderForDesktop(queryResult?.social_networks))}
  </Media>
);

FooterContainer.defaultProps = {
  queryResult: {},
};

FooterContainer.propTypes = {
  queryResult: PropTypes.shape({}),
};

export default withRootQuery(
  FooterContainer,
  SocialNetworkQuery,
  FooterContainer,
  null,
  { force: true },
);
