const CheckoutTrans = {
  En: {
    addressDeleteConfirmation: 'You will delete an address, are you sure?',
    toggleText: 'Same as the shipping address',
    billingAddressTitle: 'Billing Addresses',
    shippingAddressTitle: 'Shipping Addresses',
    checkoutTitle: 'Checkout',
    addressStepSubTitle: 'Select your shipping and billing address',
    deliveryStepSubTitle: 'Select payment and shipping methods',
    paymentStepSubTitle: 'Review payment summary and place your order',
    previousButton: 'Previous',
    saveAndContinueButton: 'Save & Continue',
    addNewAddress: 'New Address',
    onAddressSaved: 'Added to your addresses successfully',
    shippingAddressStep: 'Shipping Address',
    confirmOrderStep: 'Confirm Order',
    paymentStep: 'Payment',
    serviceError:
      'If the service(s) you are ordering will be provided at your residence; we recommend selecting your address.',
    checkFollowingError: 'Check the following errors:',
    defaultAddress: 'Default',
    buildingNo: 'Building No.',
    zone: 'Zone',
    zoneAr: 'منطقة',
    zoneNo: 'Zone No.',
    street: 'Street',
    streetAr: 'شارع',
    buildingNumber: 'Building No.',
    buildingNumberAr: 'رقم المبنى',
    streetNo: 'Street No.',
    InwaniError: 'Please enter a valid Inwani numbers!',
    globalError: 'Please add the location on the map!',
    inwaniButton: 'Find Me',
    saveButton: 'Save',
    addButton: 'Add',
    cancelButton: 'Cancel',
    totalPrice: 'Total Item(s) Price',
    totalShipping: 'Total Shipping Fees',
    totalPromo: 'Total Coupon Discount',
    totalOrder: 'Total Order',
    promoLabel: 'Coupons',
    applyPromoButton: 'Apply',
    appliedPromoButton: 'Applied',
    promoCodePlaceholder: 'Enter coupon code',
    selectPromo: 'Select coupon code',
    promoMsg: 'Coupon applied successfully',
    shippingModalTitle: 'Shipping Methods',
    shippingModalSubTitle: 'Please select the shipping method',
    shippingModalButton: 'Confirm',
    paymentLabel: 'Payment',
    shippingLabel: 'Shipping',
    noteLabel: 'Notes',
    noteSubLabel: 'Add notes here',
    subTotalLabel: 'Subtotal',
    Country: 'Country',
    City: 'City',
    Address: 'Address',
    FirstName: 'First Name',
    LastName: 'Last Name',
    MobileNumber: 'Mobile Number',
    totalLabel: 'Total',
    taxesLink: 'Taxes Included',
    cashMethod: ' Cash',
    onlineMethod: 'Online',
    errorTitle: 'Your checkout process failed',
    paymentButton: 'Place Order',
    trackOrderButton: 'Track Your Orders',
    continueShoppingButton: ' Continue Shopping',
    successTitle: 'Successfully Ordered',
    subTitleSuccessPage: 'Thank you for shopping with us!',
    orderNumber: 'Your order number: ',
    saveAsDefault: 'Set this address as default',
    promoPlaceholder: 'Select or add coupon code',
    orLabel: 'Or',
    promoText: 'Paid by coupon',
    fixErrorLink: 'Fix Error',
    noAvailableError: 'Unavailable for your address! Please check the available shipping/payment options from the shop info.',
    addNoteLink: 'Add Notes',
    serviceNote: 'If the service is for someone else, please add a note.',
    noOptionsText: 'Enter your coupon code',
    unapplyButton: 'Unapply',
    dropDownLabel: 'Add coupon code',
    TotalTaxes: 'Total Taxes',
    inwani: 'Inwani',
    setAsDefaultChangeText: "This is the default address, you shouldn't unset directly. To set another default address, go check it directly",
    successMessage: 'Address saved successfully',
  },
  Ar: {
    addressDeleteConfirmation: 'سوف تحذف عنوانًا، هل أنت متأكد؟',
    toggleText: 'نفس عنوان الشحن',
    billingAddressTitle: 'عناوين الفوترة',
    shippingAddressTitle: 'عناوين الشحن',
    checkoutTitle: 'خطوات الدفع',
    addressStepSubTitle: 'حدد عنوان شحنك ودفعك',
    previousButton: 'السابق',
    saveAndContinueButton: 'حفظ ومتابعة',
    addNewAddress: 'عنوان جديد',
    onAddressSaved: 'تمت الإضافة إلى عناوينك بنجاح',
    shippingAddressStep: 'عنوان الشحن',
    confirmOrderStep: 'تأكيد الطلب',
    paymentStep: 'الدفع',
    serviceError: 'إذا كانت الخدمة (الخدمات) التي تطلبها ستقدَّم في محل إقامتك؛ نوصيك بتحديد عنوانك.',
    checkFollowingError: 'يرجى مراجعة الأخطاء التالية:',
    defaultAddress: 'الافتراضي',
    buildingNo: 'رقم المبنى',
    zone: 'Zone',
    zoneAr: 'منطقة',
    zoneNo: 'رقم المنطقة',
    street: 'Street',
    streetAr: 'شارع',
    buildingNumber: 'Building No.',
    buildingNumberAr: 'رقم المبنى',
    streetNo: 'رقم الشارع',
    InwaniError: 'يرجى إدخال أرقام صالحة في لوحة عنواني!',
    globalError: 'يرجى إضافة العنوان على الخريطة!',
    inwaniButton: 'اعثر عليَّ',
    saveButton: 'حفظ',
    addButton: 'إضافة',
    cancelButton: 'إلغاء',
    totalPrice: 'إجمالي قيمة العناصر ',
    totalShipping: 'إجمالي رسوم الشحن',
    totalPromo: 'إجمالي خصم الكوبون',
    totalOrder: 'إجمالي الطلب',
    promoLabel: 'الكوبونات',
    applyPromoButton: 'إضافة',
    promoCodePlaceholder: 'أدخل رمز الكوبون',
    selectPromo: 'اختر رمز الكوبون',
    promoMsg: 'تمت إضافة الكوبون بنجاح',
    shippingModalTitle: 'طرق الشحن',
    shippingModalSubTitle: 'يرجى اختيار طريقة الشحن',
    shippingModalButton: 'تأكيد',
    paymentLabel: 'الدفع',
    shippingLabel: 'الشحن',
    noteLabel: 'ملاحظات',
    noteSubLabel: 'أضف ملاحظات',
    subTotalLabel: 'المجموع',
    Country: 'الدولة',
    City: 'المدينة',
    Address: 'العنوان',
    FirstName: 'الاسم الأول',
    LastName: 'الاسم الأخير',
    MobileNumber: 'رقم الجوال',
    totalLabel: 'الإجمالي',
    taxesLink: 'شامل الضرائب',
    cashMethod: ' نقدًا',
    onlineMethod: 'أونلاين',
    errorTitle: 'فشلت عملية الدفع الخاصة بك',
    paymentButton: 'تقديم الطلب',
    trackOrderButton: 'تتبع طلباتك',
    continueShoppingButton: ' متابعة التسوق',
    successTitle: 'تم الطلب بنجاح',
    subTitleSuccessPage: 'سعدنا بتسوقكم معنا!',
    orderNumber: 'رقم طلبك: ',
    saveAsDefault: 'ضبط هذا العنوان كافتراضي',
    promoPlaceholder: 'اختر أو أضف رمز الكوبون ',
    appliedPromoButton: 'تمت الإضافة',
    orLabel: 'أو',
    promoText: 'دفعت من الكوبون',
    fixErrorLink: 'تصحيح الخطأ',
    noAvailableError: 'غير متاح لعنوانك! يرجى التحقق من خيارات الشحن/الدفع المتاحة من معلومات المحل.',
    addNoteLink: 'أضف ملاحظات',
    serviceNote: 'إذا كانت الخدمة لشخص آخر يرجى إضافة ملحوظة بذلك.',
    noOptionsText: 'أدخل رمز كوبونك',
    unapplyButton: 'إلغاء',
    dropDownLabel: 'أضف رمز الكوبون',
    TotalTaxes: 'إجمالي الضرائب',
    deliveryStepSubTitle: 'اختر طرق الشحن والدفع',
    paymentStepSubTitle: 'ملخص الدفع والخروج',
    inwani: 'عنوانى',
    setAsDefaultChangeText: 'هذا هو العنوان الافتراضي ، لا ينبغي عليك التعيين مباشرة. لتعيين عنوان افتراضي آخر ، يمكنك الاطلاع عليه مباشرة',
    successMessage: 'تم حفظ العنوان بنجاح',
  },
};
export default CheckoutTrans;
