/* eslint-env browser */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import 'regenerator-runtime/runtime';

import {
  RelayNetworkLayer,
  urlMiddleware,
  loggerMiddleware,
  errorMiddleware,
  perfMiddleware,
  authMiddleware,
  cacheMiddleware,
} from 'react-relay-network-modern';

import RelayContext from '~/modules/core/utils/relayHelpers/RelayContext';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import assert from '~/modules/core/utils/jsHelpers/assert';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { currentCurrency } from '~/modules/ecommerceCore/utils/changeCurrency';
import { closeCurrentModal } from '~/modules/core/utils/modalHelpers';

import findErrorsForCode from './findErrorsForCode';
import sendErrorToCloudwatch from '../../../../errorBoundaries';
import makeid from '~/makeDeviceFingerprint';

export const PathRedirectKeysMapper = {
  showSuccess: 'showSuccess',
  login: 'login',
  pathAfterConnectSocialMedia: 'pathAfterConnectSocialMedia',
};

const deviceFingerprintID = "6S6pH5xBApMuhyJTSj6YId3RT+QlQdY8e+QES3N3UVt/3Cwa8FRQWhAa+BWz66o75OyWaRHuVewQWwLCiFTJumIU87NlhJR2tqZhqBTR++heOHeOpG9q1n9Os9U6gCSoC98TrFPc/3MWQP4c8DVs8pHIPva0Qke1ggt/cRrCkl0MpePE9X3QlENps4fdFd3ht=rL0hLOm2tD5GzczG9JdRjD9hrN9qgrMVl2dU9FFOu0EORoSlaLSjhFVkcnESl/7Nbatm9wYiPsQWnsZV9iDyF=3YQlrCdu7xNP0/8seZ4MfP/Ve6FKnNn+mhFzWxFKGsjp0nqZ003RrvsfqUzh=owQ2fPSWYgkH0X6n1jLeEg3kE0Lescfpv72XEXlfhnDt9yLRLcoQI9sKt9ni/oMJAOOk/hZ34nj8swFpp7AjqE2N=8h3kCkm6v4rl1c=dOM3786YsPkrMFnaJuEALg+CaPz8QrtOkvUM1kJ1snOt79kki72YLc+4PtzquO9JB/acvPrB2TXn13n+VbV9kP6ObvEcEcu5QjaB5o0cJDVP=Fw93M8/c5W/SP=VR78nPosVSA8jgo950ox+Dyv0uQ7k=X5oYFoHxVNgsUY8q1lK80NMB1DYmq5fcfq2aa8RtuESwQeWgnQNgmWMWFrBh=fAZja4gXvLs+ldbNGvfgyJxc1ICJx0PjB5RQK0itQTKfdDJSfc6/UztwHtQf2XaHPL4dHCMVtZVNRz45MSl9H8u2Yshobn49HGz11t161UO6ccW7i2Qfr1bY0XfQONdbdeirnZ3YxzUsX515Z2u5/IaMlpEfYBQp4QglCv5S7yk8zVMz3gxax/Bncl2WEIlzJ6bdPLpLqg0GI+scqBw=q+WhCv6OK4KuJNHH=JO0=PI3TQASdsoLWS/JioZi9Y495KUhQfiR2w2ON858bj6D16Oicwd=+w9irSxiNS4C/btkueugKT9V9AorEiAG0EX4LXZbRPf3XCFrKfFKBtz3T"

const { Environment, RecordSource, Store } = require('relay-runtime');

const enableLogging = false; // process.env.NODE_ENV;
const defaultPortalEndPoint = `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`;

const ENABLE_CACHING = true;

const addToPath = (path, additionalComponent) => path.replace(/\/?$/, additionalComponent);

class RelayProvider extends React.Component {
  constructor(props) {
    super(props);
    this.initialization(props);
  }

  // eslint-disable-next-line react/sort-comp
  initialization(props) {
    this.portalEndPoint = props.portalEndPoint || defaultPortalEndPoint;
    this.portalEndPoint = `${this.portalEndPoint}?locale=${props.locale}&currency=${currentCurrency()}`;

    assert(
      this.portalEndPoint,
      "Back-end endpoint isn't set correctly, pass it as a prop, or call the npm build (or start), as follows : 'REACT_APP_GRAPHQL_ENDPOINT=https://ayk-test-portal.badrit.com npm start''",
    );

    const store = new Store(new RecordSource());
    const network = this.createNetwork();

    this.env = new Environment({
      network,
      store,
    });
  }

  createNetwork = () => {
    const cacheSize = 200; // max 200 requests
    const cacheTime = 15 * 60 * 1000; // 15 * oneMinute

    // array of middlewares
    const middlewaresArray = [
      ...[ENABLE_CACHING ? (
        cacheMiddleware({
          size: cacheSize,
          ttl: cacheTime,
        })
      ) : (
        null
      )],
      urlMiddleware({
        url: this.portalEndPoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'DEVICE-FINGERPRINT': deviceFingerprintID,
        },
      }),
      authMiddleware({
        header: 'token',
        token: (req) => {
          if (this.props.userInfo && this.props.userInfo.token) {
            req.fetchOpts.headers = {
              ...req.fetchOpts.headers,
              token: this.props.userInfo.token,
              client: this.props.userInfo.clientID,
              expiry: this.props.userInfo.expiry,
              uid: this.props.userInfo.email,
              'token-type': this.props.userInfo.tokenType || 'Bearer',
            };
          }
        },
      }),
      // Middlewares Used For Debugging Purpose
      enableLogging ? loggerMiddleware() : null,
      enableLogging ? errorMiddleware() : null,
      enableLogging ? perfMiddleware() : null,
      next => async (req) => {
        const response = await next(req);
        if (!response.ok) {
          // send errors to cloudwatch
          const { status, url: graphqlUrl, headers } = response;
          sendErrorToCloudwatch({
            status,
            graphqlUrl,
            'x-request-id': headers.get('x-request-id'),
          });

          this.props.notifyAlert({
            messageText: `Server Error: ${response.error.message}`,
            type: AlertTypes.error,
            toastID: 'RelayProvider_error_toast',
          });
        } else {
          const error404 = findErrorsForCode(404, response.data);
          const error401 = findErrorsForCode(401, response.data);

          if (error404) {
            this.props.invalidateUser();
          }
          if (error401 && window.location.href.indexOf('forbidden') === -1) {
            closeCurrentModal(this.props.history);
            const newPath = addToPath(this.props.history.location.pathname, '/forbidden');
            this.props.history.replace(newPath);
          }
        }

        return response;
      },
    ];

    const network = new RelayNetworkLayer(middlewaresArray);

    return network;
  };

  render() {
    return <RelayContext.Provider value={this.env}>{this.props.children}</RelayContext.Provider>;
  }
}

RelayProvider.propTypes = PropTypes.shape({
  children: PropTypes.shape({}),
}).isRequired;

export default withRouter(withAlertMsg(withUserInfo(RelayProvider)));
export { default as withRelayEnvironment } from './withRelayEnvironment';
