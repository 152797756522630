/**
 * @flow
 * @relayHash 5fce0823582b566f55c65360eef2073c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecommendedProductQueryVariables = {||};
export type RecommendedProductQueryResponse = {|
  +recommended_products: ?{|
    +higlighted: ?$ReadOnlyArray<?{|
      +avg_rating: ?number,
      +discount: ?number,
      +has_discount: ?boolean,
      +has_offer: ?boolean,
      +hot_deal: ?boolean,
      +id: string,
      +images: $ReadOnlyArray<?string>,
      +min_variant: ?{|
        +slug: ?string,
        +name: string,
        +ref_id: string,
        +root_variant_id: ?string,
        +discount_expire_at: ?any,
        +remaining_usage_percentage: ?number,
        +total_on_hand: ?number,
        +price: number,
        +original_price: number,
      |},
      +rating_count: ?number,
      +ref_id: string,
      +vendor_variants_count: ?number,
      +wish_listed: ?boolean,
    |}>,
    +result: ?$ReadOnlyArray<?{|
      +avg_rating: ?number,
      +discount: ?number,
      +has_discount: ?boolean,
      +has_offer: ?boolean,
      +hot_deal: ?boolean,
      +id: string,
      +images: $ReadOnlyArray<?string>,
      +min_variant: ?{|
        +slug: ?string,
        +name: string,
        +ref_id: string,
        +root_variant_id: ?string,
        +discount_expire_at: ?any,
        +remaining_usage_percentage: ?number,
        +total_on_hand: ?number,
        +price: number,
        +original_price: number,
      |},
      +rating_count: ?number,
      +ref_id: string,
      +vendor_variants_count: ?number,
      +wish_listed: ?boolean,
    |}>,
  |}
|};
export type RecommendedProductQuery = {|
  variables: RecommendedProductQueryVariables,
  response: RecommendedProductQueryResponse,
|};
*/


/*
query RecommendedProductQuery {
  recommended_products {
    higlighted {
      avg_rating
      discount
      has_discount
      has_offer
      hot_deal
      id
      images
      min_variant {
        slug
        name
        ref_id
        root_variant_id
        discount_expire_at
        remaining_usage_percentage
        total_on_hand
        price
        original_price
        id
      }
      rating_count
      ref_id
      vendor_variants_count
      wish_listed
    }
    result {
      avg_rating
      discount
      has_discount
      has_offer
      hot_deal
      id
      images
      min_variant {
        slug
        name
        ref_id
        root_variant_id
        discount_expire_at
        remaining_usage_percentage
        total_on_hand
        price
        original_price
        id
      }
      rating_count
      ref_id
      vendor_variants_count
      wish_listed
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "images",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "has_discount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "has_offer",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hot_deal",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount_expire_at",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "remaining_usage_percentage",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total_on_hand",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "vendor_variants_count",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
},
v19 = [
  v0,
  v1,
  v2,
  v3,
  v4,
  v5,
  v6,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "min_variant",
    "storageKey": null,
    "args": null,
    "concreteType": "Variant",
    "plural": false,
    "selections": [
      v7,
      v8,
      v9,
      v10,
      v11,
      v12,
      v13,
      v14,
      v15
    ]
  },
  v16,
  v9,
  v17,
  v18
],
v20 = [
  v0,
  v1,
  v2,
  v3,
  v4,
  v5,
  v6,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "min_variant",
    "storageKey": null,
    "args": null,
    "concreteType": "Variant",
    "plural": false,
    "selections": [
      v7,
      v8,
      v9,
      v10,
      v11,
      v12,
      v13,
      v14,
      v15,
      v5
    ]
  },
  v16,
  v9,
  v17,
  v18
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RecommendedProductQuery",
  "id": null,
  "text": "query RecommendedProductQuery {\n  recommended_products {\n    higlighted {\n      avg_rating\n      discount\n      has_discount\n      has_offer\n      hot_deal\n      id\n      images\n      min_variant {\n        slug\n        name\n        ref_id\n        root_variant_id\n        discount_expire_at\n        remaining_usage_percentage\n        total_on_hand\n        price\n        original_price\n        id\n      }\n      rating_count\n      ref_id\n      vendor_variants_count\n      wish_listed\n    }\n    result {\n      avg_rating\n      discount\n      has_discount\n      has_offer\n      hot_deal\n      id\n      images\n      min_variant {\n        slug\n        name\n        ref_id\n        root_variant_id\n        discount_expire_at\n        remaining_usage_percentage\n        total_on_hand\n        price\n        original_price\n        id\n      }\n      rating_count\n      ref_id\n      vendor_variants_count\n      wish_listed\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RecommendedProductQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "higlighted",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v19
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v19
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RecommendedProductQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "higlighted",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v20
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v20
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b6063747b67b3450ad5d58c13daa3e81';
module.exports = node;
