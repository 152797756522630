/* eslint-env browser */
/* eslint-disable */
import React from 'react';
import i18next from 'i18next';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { Column, CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { SocialMediaSectionTitle } from '~/modules/accountManagement/components/basic/Labels';
import { XXXXXLargeSpacer, XXLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { mediaQueryMin, mediaQueryMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Loader from '~/modules/coreUI/components/basic/Loader';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import firebase from 'firebase/app';
import 'firebase/auth';

import LoginWithSocialMedia from '~/modules/accountManagement/containers/panels/socialMedia/LoginWithSocialMedia';
import extractSocialMediaData from '~/modules/accountManagement/utilities/userData';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

const socialMedias = [
  {
    key: 'accountManagement:SocialMedia.Facebook',
    name: 'Facebook',
    icon: 'icon-fb',
    background: '#3b5998',
  },
  {
    key: 'accountManagement:SocialMedia.Twitter',
    name: 'Twitter',
    icon: 'icon-twitter',
    background: '#1da1f2',
  },
  {
    key: 'accountManagement:SocialMedia.GooglePlus',
    name: 'Google Plus',
    icon: 'el-icon el-icon-google',
    background: '#4285f4',
  },
];

const SocialMedia = (props) => {
  console.log('Props', props);
  const History = props.history;
  let isLanding = false;
  const { pathname } = props.location;

  if (pathname.includes('loginWithSocialMedia')) {
    isLanding = true;
  }

  const getStyle = () => {
    const style = {
      display: 'none',
    };

    return isLanding ? style : {};
  };
  const appleBtnStyle = {
    width: '117px',
    height: '35px',
    background: '#000',
    color: '#fff',
    outline: 'none',
    border: 'none',
    borderRadius: '3px',
    fontSize: '9.5px',
    padding: '0px 20px',
    cursor: 'pointer',
  };
  const appleBtnStyleAr = {
    width: '117px',
    height: '35px',
    background: '#000',
    color: '#fff',
    outline: 'none',
    border: 'none',
    borderRadius: '3px',
    fontSize: '9.5px',
    cursor: 'pointer',
    padding: '0px 15px',
  }
  const appleIcon = {
    float: 'left',
    fontSize: '25px',
    marginRight: '10px',
  };
  const appleIconAr = {
    float: 'right',
    fontSize: '25px',
    marginLeft: '10px',
    marginRight: '0px',
  }
  const appleBtnInfo = {
    float: 'left',
    textAlign: 'start'
  };
  const btnName = {
    display: 'block',
    clear: 'both',
    fontSize: '11px',
    fontWeight: '600',
  };
  const handelAppleSignup = () => {
    var provider = new firebase.auth.OAuthProvider('apple.com');
    firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
        var credential = result.credential;
        var user = result.user;
        var accessToken = credential.accessToken;
        var idToken = idToken;
        const resObject = {
          _profile: {
            email: user.email,
            firstName: user.displayName.split(" ")[0],
            lastName: user.displayName.split(" ")[1],
            name: user.displayName,
          },
          _provider: 'apple',
          _token: {
            accessToken: accessToken,
            idToken: idToken,
          },
        }
        const socialMediaReturnedUserInfo = extractSocialMediaData(resObject);
        sessionStorage.setItem('SMData', JSON.stringify(socialMediaReturnedUserInfo));
        localStorage.getItem('SMData');
        navigateToModal(History, '/accountManagement/socialMediaLanding');
      }).catch(function(error) {
        
      });
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
    });
  };
  return (
    <React.Fragment>
      <Column justifyCenter centerAligned fullWidth style={getStyle()}>
        <Media
          query={mediaQueryMin('desktop')}
          render={() => <Separator separatorLength="xLarge" />}
        />
        <XXLargeSpacer />
        <CenterAlignedColumn>
          <SocialMediaSectionTitle>
            {i18next.t('accountManagement:SocialMedia.CONNECTWITH')}
          </SocialMediaSectionTitle>

          <Spacer size={2} />
          <Row spaceBetween={1}>
            {socialMedias.map(socialMedia => (
              <LoginWithSocialMedia key={socialMedia.name} socialMedia={socialMedia} />
            ))}
            <button className="signup-apple-btn" style={i18next.t('accountManagement:SocialMedia.Apple') == 'Apple' ? appleBtnStyle : appleBtnStyleAr} onClick={handelAppleSignup}>
              <i style={i18next.t('accountManagement:SocialMedia.Apple') == 'Apple' ? appleIcon : appleIconAr} class="fab fa-apple"></i>
              <div className="signup-apple-info" style={appleBtnInfo}>
                <Trans i18nKey="LogInWith" />
                <span style={btnName}>{i18next.t('accountManagement:SocialMedia.Apple')}</span>
              </div>
            </button>
          </Row>
          <XXXXXLargeSpacer />
          <Media query={mediaQueryMax('tablet')} render={() => <XXXXXLargeSpacer />} />
        </CenterAlignedColumn>
      </Column>

      {isLanding && (
      <Column fullWidth fullHeight centerJustified>
        <Loader />
      </Column>
      )}
    </React.Fragment>
  );
};

SocialMedia.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(SocialMedia);
