/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { commitMutation } from 'react-relay';

import t from 'tcomb-form';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import _ from 'lodash';

import cuid from 'cuid';

import assert from '~/modules/core/utils/jsHelpers/assert';
import objectsDeepNotEqualComparison from '~/modules/core/utils/jsHelpers/objectsDeepComparison';

import {
  getTcombOptionsFromRawOptions,
  getTcombTypesFromRawOptions,
} from '~/modules/coreUI/components/forms/TcombHelpers';

import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

// that what eslint need
const { Form } = t.form;

class SeededTcombForm extends Form {
  // Without this, all forms' input fields will have
  // similar generated ids, and browswer will complaint
  getSeed = cuid;
}

const FullWidthForm = styled.form`
  width: 100%;
`;
class RelayForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tcombOptions: getTcombOptionsFromRawOptions(props.options),
      value: props.options.initialFormValue,
      isLoading: false,
    };
  }

  // track component live
  _isMounted = true;

  componentWillUnmount() {
    this._isMounted = false;
  }

  // will use this insted of setState it will check first if compoennt is live
  safeSetState(...args) {
    if (this._isMounted) {
      this.setState(...args);
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  onChange = (value, path) => {
    this.updateFormValue(value, path);
  };

  onLoading = (isLoading) => {
    this.safeSetState({ isLoading }, () => {
      this.props.onFormLoading(isLoading);
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();
  };

  getFieldName = path => path[0];

  getValue = () => this.Form.getValue();

  validateForm = () => {
    this.resetTcompOptionsErrors();

    /* eslint-disable no-unused-expressions */
    _.values(this.state.tcombOptions?.fields).forEach((field) => {
      if (field.attrs.validate) {
        const error = field.attrs.validate(this.state.value);
        this.updateTcompOptionsWithErrors({
          [field.attrs.name]: error,
        });
      }
    });
    /* eslint-enable no-unused-expressions */

    return this.getValue();
  }

  validateFormAsync = async () => {
    const formValues = this.validateForm();
    if (!formValues) {
      return null;
    }

    const fieldsWithAsyncValidations = _.filter(
      this.state.tcombOptions?.fields,
      field => field.attrs.supportAsyncValidation && this.Form.inputRef.childRefs[field.attrs.name],
    );

    const results = await Promise.all(fieldsWithAsyncValidations.map(async (field) => {
      assert(this.Form.inputRef.childRefs[field.attrs.name].asyncValidate, `The field '${field.name}', is marked as 'supportAsyncValidation', but it's Factory, doesn't provide an 'asyncValidate' method`);
      return this.Form.inputRef.childRefs[field.attrs.name].asyncValidate(formValues);
    }));

    const someAsyncValidationFailed = _.some(results, result => !result);
    return someAsyncValidationFailed ? null : formValues;
  }

  onAsyncValidationError = (fieldName, error) => this.updateTcompOptionsWithErrors({
    [fieldName]: error,
  });

  getCommitFormMutationVariables = () => {
    let formVariables;
    if (window.location.href.includes('/login')) {
      formVariables = { ...this.getValue() };
      Object.keys(formVariables).forEach((k) => { if (typeof (formVariables[k]) === 'string') { formVariables[k] = formVariables[k]?.trim(); } });
    } else {
      formVariables = this.getValue();
    }
    if (this.props.getSubmissionVariables) {
      return this.props.getSubmissionVariables(formVariables);
    }

    const addiontalMutationVariables = this.props.addiontalMutationVariables || {};

    return {
      ...formVariables,
      ...addiontalMutationVariables,
    };
  };


  submitForm = (async = false) => {
    const {
      onFormError,
      onFormSuccess,
      mutationRoot,
      environment,
      mutation,
      errorsRoot,
    } = this.props;

    this.resetTcompOptionsErrors();

    // onFormError & onFormSuccess shouldn't be empty or undefined
    assert(onFormError, 'onFormError Should Not Be Undefined');
    assert(onFormSuccess, 'onFormSuccess Should Not Be Undefined');

    if (this.state.isLoading) {
      return null;
    }
if (!(window.location.href.includes('/account/profile') && window.innerWidth < 600)) { // applying in all forms except edit profile
  if (!this.validateForm()) {
      return null;
    }
}


    // Apply local validations first
    this.Form.validate();

    const formValues = this.Form.getValue();
    if (!formValues) {
      onFormError(null);
      return null;
    }

    const variables = this.getCommitFormMutationVariables();

    this.onLoading(true);

    const commitMutationWithCallbacks = (resolve, reject) => this.commitFormMutation(environment, mutation, variables, mutationRoot, errorsRoot, {
      preCommit: () => {},
      completed: (response, errors) => {
        if (errors) {
          this.updateTcompOptionsWithErrors(errors);
        }

        const errorsExist = errors.generalErrors && (Object.keys(errors.generalErrors).length > 0 || errors.generalErrors.length > 0);
        const LocalErrorsExist = errors && (Object.keys(errors).length > 0 || errors.length > 0);

        if (errorsExist) {
          onFormError(errors);
          if (reject) {
            reject(errors);
          }
        } else {
          onFormError(null);
        }
        this.onLoading(false);
        if (!errorsExist && !LocalErrorsExist) {
          onFormSuccess(response);
          if (resolve) {
            resolve(response);
          }
        }
      },
    });

    if (async) {
      return new Promise((resolve, reject) => {
        commitMutationWithCallbacks(resolve, reject);
      });
    }
    commitMutationWithCallbacks();
    return null;
  };

  resetForm = () => {
    this.safeSetState({ value: {} });
  }


  commitFormMutation = (environment, mutation, variables, mutationRoot, errorsRoot, callbacks) => {
    const knownFields = {};
    if (this.state.tcombOptions.fields) {
      Object.keys(this.state.tcombOptions.fields).forEach((field) => {
        knownFields[field] = true;
      });
    }
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        const serverErrors = {};
        const globalError = errors && errors.length > 0 && errors[0];

        if (globalError) {
          serverErrors.generalErrors = globalError.message;
        }

        const unKnownFieldsErrors = {};
        const errorsRootWithDefault = errorsRoot || 'errors';

        if (response && response[mutationRoot] && response[mutationRoot][errorsRootWithDefault]) {
          response[mutationRoot][errorsRootWithDefault].forEach((error) => {
            const errorMessage = `${error.message || error.messages[0]}`;
            if (knownFields[error.field]) {
              serverErrors[error.field] = errorMessage;
            } else {
              unKnownFieldsErrors[error.field] = errorMessage;
            }
          });
        }

        if (Object.keys(unKnownFieldsErrors).length > 0) {
          serverErrors.generalErrors = unKnownFieldsErrors;
        }

        // form to render to show server errors (When no local errors are there)
        callbacks.completed(response, serverErrors);
      },

      onError: (err) => {
        callbacks.completed(null, err.message || err.toString());
      },
    });
  };

  updateFormValue = (value, path) => {
    const isNewValueReceived = objectsDeepNotEqualComparison(value, this.state.value);
    let newValue = value;

    if (isNewValueReceived) {
      // TODO : Extract to method (apply cascading updates)
      let cascadingUpdates = _.mapValues(this.state.tcombOptions.fields, fieldOptions => (fieldOptions.attrs.getUpdatesOnFormValueChange
        ? fieldOptions.attrs.getUpdatesOnFormValueChange(value, path)
        : null));
      cascadingUpdates = _.pickBy(cascadingUpdates, entry => !!entry);
      newValue = t.update(value, cascadingUpdates);
    }

    this.safeSetState({ value: newValue });
  };

  updateTcompOptionsWithErrors(fieldsErrors) {
    const { options } = this.props;
    const fields = {};

    options.fields.forEach((option) => {
      const error = fieldsErrors[option.name];
      if (error) {
        fields[option.name] = {
          hasError: {
            $set: !!error,
          },
          error: {
            // FIXME : Replace the 'SERVER_ERROR: ' part, with a more elegant solution.
            //        It's used mainly in the Errors.jsx to differentiate local & server errors
            $set: `SERVER_ERROR: ${error}`,
          },
        };
      } else {
        fields[option.name] = {
          error: {
            $set: null,
          },
        };
      }
    });

    this.safeSetState(prevState => ({
      tcombOptions: t.update(prevState.tcombOptions, { fields }),
    }));
  }

  resetTcompOptionsErrors(fieldName) {
    const { options } = this.props;
    const fields = {};

    const fieldsToChange = fieldName ? [fieldName] : options.fields.map(option => option.name);

    fieldsToChange.forEach((field) => {
      fields[field] = {
        hasError: {
          $set: false,
        },
        error: {
          $set: null,
        },
      };
    });

    this.safeSetState(prevState => ({
      tcombOptions: t.update(prevState.tcombOptions, { fields }),
    }));
  }

  render = () => {
    const { options } = this.props;

    const { isLoading } = this.state;

    const type = getTcombTypesFromRawOptions(options);

    // FIXME: Nested values needs different handling
    const formValues = {
      ...options.initialFormValue,
      ...this.state.value,
    };

    return (
      <FullWidthForm
        // External form helps with Autocomplete from browsers
        onSubmit={this.onSubmit}
      >
        <SeededTcombForm
          ref={(ref) => {
            this.Form = ref;
          }}
          type={type}
          options={this.state.tcombOptions}
          value={formValues}
          onChange={(value, path) => this.onChange(value, path)}
          context={{
            customInputsContainer: options.customInputsContainer, // Options are not being passed
            // to Form Layout, so that we put it in context
            isLoading,
            onSubmit: this.submitForm,
            onKeyUp: (event) => {
              event.preventDefault();
              event.stopPropagation();
              if (event.keyCode === 13) {
                this.submitForm();
              }
            },
            onAsyncValidationError: this.onAsyncValidationError,
            currentFormValues: formValues,
            initialFormValue: options.initialFormValue,
            ...this.props.context,
          }}
        />
      </FullWidthForm>
    );
  };
}

RelayForm.propTypes = PropTypes.shape({
  options: PropTypes.shape({
    initialFormValue: PropTypes.shape({}),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        input_type: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        displayName: PropTypes.string,
        customInputsContainer: PropTypes.element,
        customLayout: PropTypes.func,
      }),
    ),
  }).isRequired,
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
  addiontalMutationVariables: PropTypes.shape({}),
  onChange: PropTypes.func,
}).isRequired;

export default withRelayEnvironment(RelayForm);
