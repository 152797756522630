/* eslint-env browser */
/* eslint-disable */

function getPageTitle() {
    var baseUrl = window.location.pathname;
    var title;
    var str = baseUrl;
    if(str.split("/")[1] == "ar"){
        if(str.split("/")[2] == 'products' || str.split("/")[3] == 'product'){
            title = "  أيك مول | المنتجات"
        }else if(str.split("/")[2] == 'shops' || str.split("/")[3] == 'shops'){
            title = "  أيك مول | المتاجر"
        }else if(str.split("/")[2] == 'homemade' || str.split("/")[3] == 'homemade'){
            title = "  أيك مول | الصناعة المنزلية"
        }else if(str.split("/")[2] == 'healthPark' || str.split("/")[3] == 'healthPark'){
            title = "  أيك مول | واحة الصحة"
        }else if(str.split("/")[2] == 'eduPark' || str.split("/")[3] == 'eduPark'){
            title = "  أيك مول | واحة التعليم "
        }else if(str.split("/")[2] == 'terms_conditions'){
            title = "  أيك مول | الشروط والأحكام "
        }else if(str.split("/")[2] == 'privacy_policy'){
            title = "  أيك مول | سياسة الخصوصية "
        }else if(str.split("/")[2] == 'return_and_refund_condition'){
            title = "  أيك مول | سياسة إرجاع المبالغ "
        }else if(str.split("/")[2] == 'support'){
            title = "  أيك مول | مركز المساعدة   "
        }else if(str.split("/")[2] == 'about_us'){
            title = "  أيك مول | من نحن   "
        }else if(str.split("/")[2] == 'contact_us'){
            title = "  أيك مول | تواصل معنا "
        }else if(str.split("/")[2] == 'cscm'){
            title = "  أيك مول | اسأل أيك  "
        }else if(str.split("/")[2] == 'join'){
            title = "  أيك مول | الانضمام كعمل تجاري   "
        }else if(str.split("/")[2] == 'get_started'){
            title = "  أيك مول | البدء في الاستخدام   "
        }else if(str.split("/")[2] == 'shops_and_providers'){
            title = "  أيك مول | المحلات والمخزون   "
        }else if(str.split("/")[2] == 'payment'){
            title = "  أيك مول | الدفع"
        }else if(str.split("/")[2] == 'account' && str.split("/")[3] == 'csrv'){
            title = "  أيك مول | الحساب"
        }else if(str.split("/")[2] == 'orders'){
            title = "  أيك مول | الطلبات"
        }else if(str.split("/")[2] == 'others'){
            title = "  أيك مول | أخرى"
        }else if(str.split("/")[2] == 'offers_and_discounts'){
            title = "  أيك مول | العروض والخصومات"
        }else if(str.split("/")[3] == 'profile'){
            title = "  أيك مول | الملف الشخصي "
        }else if(str.split("/")[3] == 'wishlist'){
            title = "  أيك مول | الرغبات "
        }else if(str.split("/")[3] == 'my-orders'){
            title = "  أيك مول | الطلبات "
        }else if(str.split("/")[3] == 'my-refunds'){
            title = "  أيك مول | استعادة المبالغ "
        }else if(str.split("/")[2] == 'cart'){
            title = "  أيك مول | عربة التسوق "
        }else if(str.split("/")[3] == 'recent-viewed'){
            title = "  أيك مول | المشاهدات الاخيرة "
        }else if(str.split("/")[3] == 'notificationsAndMessages' && str.split("/")[4] == 'notifications'){
            title = "  أيك مول | الإشعارات  "
        }else if(str.split("/")[3] == 'notificationsAndMessages' && str.split("/")[4] == 'messages'){
            title = "  أيك مول | الرسائل  "
        }else if(str.split("/")[3] == 'smartcart'){
            title = "  أيك مول | العربات الذكية  "
        }else if(str.split("/")[3] == 'following'){
            title = "  أيك مول | قائمة المتابعة  "
        }else if(str.split("/")[3] == 'loyalty'){
            title = "  أيك مول | برنامج الولاء  "
        }else if(str.split("/")[3] == 'coupons'){
            title = "  أيك مول | الكوبونات  "
        }else if(str.split("/")[3] == 'settings'){
            title = "  أيك مول | الإعدادات  "
        }else if(str.split("/")[3] == 'invite'){
            title = "  أيك مول | دعوة الأصدقاء  "
        }else if(str.split("/")[3] == 'invite'){
            title = "  أيك مول | دعوة الأصدقاء  "
        }
        console.log('page Arabic', str.split("/")[3]);
    }else{
        if(window.location.href.includes('/invite/') || window.location.href.includes('/signup')){
            title = "Ayk Mall";
        }
        if(str.slice(-1) != '/'){
            if(baseUrl == '/cscm' || baseUrl == '/en/cscm'){
                title = "Ayk Mall | Ask Ayk Mall";
            }else{
                var n = str.lastIndexOf('/');
                baseUrl = str.substring(n + 1);
                baseUrl = baseUrl.replace(/([A-Z])/g, ' $1').trim();
                baseUrl = baseUrl.replace("-", " ");
                baseUrl = baseUrl.charAt(0).toUpperCase() + baseUrl.slice(1);
                if(baseUrl == "Od"){
                    title = "Ayk Mall | Order Details";
                }else if(baseUrl == "Rd"){
                    title = "Ayk Mall | Refund Details";
                }else if(baseUrl == "Md"){
                    title = "Ayk Mall | Message Details";
                }else{
                    title = "Ayk Mall | " + baseUrl;
                }
            }
        }else{
            if(baseUrl.split("/")[2] == "sc" || baseUrl.split("/")[3] == "sc"){
                title = "Ayk Mall | Smart Cart";
            }else if(baseUrl == '/terms_conditions/stp/' || baseUrl == '/en/terms_conditions/stp/'){
                title = "Ayk Mall | Terms & Conditions";
            }else if(baseUrl == '/privacy_policy/stp/' || baseUrl == '/en/privacy_policy/stp/'){
                title = "Ayk Mall | Privacy Policy";
            }else if(baseUrl == '/return_and_refund_condition/stp/' || baseUrl == '/en/return_and_refund_condition/stp/'){
                title = "Ayk Mall | Refund Policy";
            }else if(baseUrl == '/support/stp/' || baseUrl == '/en/support/stp/'){
                title = "Ayk Mall | Help Center";
            }else if(baseUrl == '/about_us/stp/' || baseUrl == '/en/about_us/stp/'){
                title = "Ayk Mall | About Us";
            }else if(baseUrl == '/contact_us/stp/' || baseUrl == '/en/contact_us/stp/'){
                title = "Ayk Mall | Contact Us";
            }else if(baseUrl == '/join/' || baseUrl == '/en/join/'){
                title = "Ayk Mall | Join as a Business";
            }else if(baseUrl == '/get_started/csrv/' || baseUrl == '/en/get_started/csrv/'){
                title = "Ayk Mall | Help Center | Get Started FAQs";
            }else if(baseUrl == '/account/csrv/' || baseUrl == '/en/account/csrv/'){
                title = "Ayk Mall | Help Center | Account FAQs";
            }else if(baseUrl == '/products/csrv/' || baseUrl == '/en/products/csrv/'){
                title = "Ayk Mall | Help Center | Products FAQs";
            }else if(baseUrl == '/shops_and_providers/csrv/' || baseUrl == '/en/shops_and_providers/csrv/'){
                title = "Ayk Mall | Help Center | Shops & Providers FAQs";
            }else if(baseUrl == '/orders/csrv/' || baseUrl == '/en/orders/csrv/'){
                title = "Ayk Mall | Help Center | Orders FAQs";
            }else if(baseUrl == '/offers_and_discounts/csrv/' || baseUrl == '/en/offers_and_discounts/csrv/'){
                title = "Ayk Mall | Help Center | Offers & Discounts FAQs";
            }else if(baseUrl == '/payment/csrv/' || baseUrl == '/en/payment/csrv/'){
                title = "Ayk Mall | Help Center | Payment FAQs";
            }else if(baseUrl == '/others/csrv/' || baseUrl == '/en/others/csrv/'){
                title = "Ayk Mall | Help Center | Others FAQs";
            }else if(baseUrl.split("/")[2] == "t-p" || baseUrl.split("/")[3] == "t-p"){
                title = "Ayk Mall | Search Results "
            }else{
                baseUrl = baseUrl.split("-");
                if(baseUrl.length == 2){
                    const mainPageStr = baseUrl[1].split("/")[1].charAt(0).toUpperCase() + baseUrl[1].split("/")[1].slice(1);
                    const mainPage = mainPageStr.replace(/([A-Z])/g, ' $1').trim();
                    const subPage = baseUrl[1].split("/")[0].charAt(0).toUpperCase() + baseUrl[1].split("/")[0].slice(1);
                    title = "Ayk Mall | " + mainPage + " | " + subPage;
                }else if(baseUrl.length == 3){
                    if(baseUrl[1] == "discounts/c"){
                        title = "Ayk Mall | Discounts";
                    }else if(baseUrl[1] == "hot_deals/c"){
                        title = "Ayk Mall | Hot Deals";
                    }else if(baseUrl[1] == "offers/c" || baseUrl[1] == "offer/c"){
                        title = "Ayk Mall | Offers";
                    }else{
                        const mainPageStr = baseUrl[2].split("/")[1].charAt(0).toUpperCase() + baseUrl[2].split("/")[1].slice(1);
                        const mainPage = mainPageStr.replace(/([A-Z])/g, ' $1').trim();
                        const firstSubPage = baseUrl[1].charAt(0).toUpperCase() + baseUrl[1].slice(1);
                        const secondSubPage = baseUrl[2].split("/")[0].charAt(0).toUpperCase() + baseUrl[2].split("/")[0].slice(1);
                        var sign;
                        if(mainPage == "Health Park" || mainPage == "Edu Park"){
                            sign = " ";
                        }else{
                            sign = " & ";
                        }
                        title = "Ayk Mall | " + mainPage + " | " + firstSubPage + sign + secondSubPage;
                    }
                }
            }
        }
    }
    return title;
}

export default getPageTitle;