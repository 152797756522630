import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate, Trans } from 'react-i18next';
import styled from 'react-emotion';
import { css } from 'emotion';
import Media from 'react-media';
import i18next from 'i18next';
import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import { CenterAlignedRow, TopAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import { LeftAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import {
  EmphasizedImportantMinorDetailsLabel,
  EmphasizedMinorDetailsLabel,
} from '~/modules/ecommerceCoreUI/components/basic/Labels';
import { Spacer, XXSmallSpacer, XSmallSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import {
  mediaQueryMin,
  mediaQueryMax,
  cssMediaMin,
} from '~/modules/core/utils/cssHelpers/cssMedia';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

// Workaround for the cart icon (UGLY)
const customIconStyle = () => css`
  margin-top: -5px;
`;
const ShopCartNumber = withDirection(styled.span`
  text-align: center;
  width: 17px;
  height: 16px;
  position: absolute;
  z-index: 2;
  top: -2px;
  ${props => (props.direction === 'rtl'
    ? css`
      right: 13px;
      `
    : css`
      left: 13px;
  `)}
  padding: 3px 0;
  color: ${props => props.theme.colors.named.white};
  background-color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fonts.sizes.xxxSmall}px;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  border-radius: 50%;
  ${cssMediaMin.desktop`
     display: none;
  `};
`);

const ShoppingCartNumber = styled(Row)`
  color: ${props => props.theme.colors.named.white};
  background-color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fonts.sizes.xxSmall}px;
  border-radius: ${props => props.theme.borders.radius.small}px;
  padding-bottom: 1px; /* small offset to center the text vertically */
  padding-top:1px
`;

const ShopingCartContainer = styled.div`
  position: relative;
`;

const Icon = withDirection(styled.i`
  ${props => (props.direction === 'rtl'
    ? css`
        margin-right: ${props.theme.paddings.small - 1}px;
      `
    : css`
        margin-left: ${props.theme.paddings.small - 1}px;
      `
  )}
  color: #666666;
`);

const WrapperStyle = styled(CenterAlignedRow)`
  cursor: pointer;
`;

class NavProductsIcon extends React.Component {
  state = {};

  render = () => (
    <WrapperStyle onClick={this.props.onClick}>
      <Media
        query={mediaQueryMax('tablet')}
        render={() => (
          <Link href={urlFor('getCartLink')} to={urlFor('getCartLink')}>
            <ShopingCartContainer
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ShopCartNumber>{this.props.quantity}</ShopCartNumber>
              <IconOnlyButton
                customIconStyle={customIconStyle}
                primary
                title={i18next.t('ecommerceCoreUI:ToolTipTrans.userItem')}
                iconName={
                  this.props.orders && this.props.orders.length > 0
                    ? 'icon-happy-bag'
                    : 'icon-shopping-sad'
                }
                size={28}
              />
            </ShopingCartContainer>
          </Link>
        )}
      />
      <Media
        query={mediaQueryMin('desktop')}
        render={() => (
          <ShopingCartContainer>
            <ShopCartNumber>{this.props.quantity}</ShopCartNumber>
            <IconOnlyButton
              customIconStyle={customIconStyle}
              primary
              title={i18next.t('ecommerceCoreUI:ToolTipTrans.userItem')}
              tooltipLocation="bottom"
              iconName={this.props.quantity > 0 ? 'icon-happy-bag' : 'icon-shopping-sad'}
              size={28}
            />
          </ShopingCartContainer>
        )}
      />
      <Spacer />
      <Media
        query={mediaQueryMin('desktop')}
        render={() => (
          <div>
            <TopAlignedRow>
              <LeftAlignedColumn>
                <XSmallSpacer />
                <EmphasizedImportantMinorDetailsLabel>
                  {this.props.quantity === 0 ? (
                    <React.Fragment>
                      <Trans i18nKey="HeaderProductItems" />
                      <Icon className="icon-down-open" />
                    </React.Fragment>
                  ) : (
                    this.props.quantity && (
                      <React.Fragment>
                        <Row>
                          <ShoppingCartNumber>
                            <Spacer customSize={4} />
                            {this.props.quantity}
                            <Spacer customSize={4} />
                          </ShoppingCartNumber>
                          <Spacer customSize={4} />
                          {i18next.t('ecommerceOrder:Cart.items')}
                          <Icon className="icon-down-open" />
                        </Row>
                      </React.Fragment>
                    )
                  )}
                </EmphasizedImportantMinorDetailsLabel>
                <XXSmallSpacer />
                <EmphasizedMinorDetailsLabel>
                  {this.props.amount !== 'undefined' && Number(this.props.amount).toFixed(2)}
                  {' '}
                  {currentCurrencyDisplayName()}
                </EmphasizedMinorDetailsLabel>
              </LeftAlignedColumn>
            </TopAlignedRow>
          </div>
        )}
      />
    </WrapperStyle>
  );
}

NavProductsIcon.propTypes = {
  amount: PropTypes.number,
  quantity: PropTypes.number,
  orders: PropTypes.arrayOf(PropTypes.any),
  onClick: PropTypes.func.isRequired,
};
NavProductsIcon.defaultProps = {
  amount: null,
  orders: null,
  quantity: null,
};

export default translate('aykLayout')(withCartInfo(NavProductsIcon));
