import { graphql } from 'relay-runtime';

const MessagesListFragment = graphql`
   fragment MessagesList_queryResult on Query
   @argumentDefinitions(input: { type:  "ThreadListInput" }) {
    thread_list(input: $input) {
      threads_with_unread_messages_count
      unread_notifications_count
      threads{
       ...ThreadFragment_queryResult @relay(mask: false)
     }
     page_info{
      ...PaginationData_pageInfo
     }
     errors{
       field
       messages
       code
     }
    }
  }
`;

export default MessagesListFragment;
