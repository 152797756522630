/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'react-emotion';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';

import withModalTracker from '~/modules/core/utils/modalHelpers/withModalTracker';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { CenterAlignedColumn, Column } from '~/modules/coreUI/components/legacies/Columns';
import {
  cssMediaMin,
  cssMediaMax,
  mediaQueryMin,
  mediaQueryMax,
} from '~/modules/core/utils/cssHelpers/cssMedia';
import Image from '~/modules/coreUI/components/basic/Image';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');

const styles = {
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
    padding: '0px',
    borderRadius: '0px',
    border: 'none',
  },
};

const backgroundImagePath = `/${imagesPathName}/AccountManagement/panel_background/bg.png`;

export const ModalContent = styled(Column)`
  position: unset;

  height: 100%;
  width: 100%;

  ${cssMediaMin.desktop`
    width: 920px;
    height: 610px;
    position: relative;
  `}
    ${props => props.modalHasMinHeight && cssMediaMin.desktop`min-height: 610px; height: unset`};

  ${cssMediaMax.tablet`
    min-height: 100vh;
  `}

  align-items: stretch;
  justify-content: space-between;

  background-color: ${props => props.theme.colors.named.white};

  border: 1px solid;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  border-color: white;

  ${cssMediaMin.desktop`
    background-image: url(${backgroundImagePath});
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    border-top: 0px;
  `};
`;

const TabletCloseIcon = withDirection(styled(Image)`
  max-width: 17px;
  max-height: 17px;

  position: absolute;
  top: ${props => props.theme.paddings.xxLarge}px;

  ${props => (props.direction === 'rtl'
    ? css`
        left: ${props.theme.paddings.xxLarge}px;
      `
    : css`
        right: ${props.theme.paddings.xxLarge}px;
   `)};
  z-index: 3;
`);

const CloseIcon = styled(Icon)`
  &&& {
    align-self: flex-end;
    margin-bottom: 5px;

    font-size: 16px !important;
    color: white;
    cursor: pointer;
    z-index: 3;
  }
  font-size: 16px !important;
  color: white;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 1024px) {
    color: black;
  }
`;

const ModalContainer = styled.div`
  width: 100%;

  ${cssMediaMin.desktop`
    height: 100%;
  `};
  ${cssMediaMax.tablet`
    position: absolute;
    top: 0;
    min-height: 100vh;
  `};
  ${props => props.tabletOverflow && cssMediaMax.tablet`overflow-x: hidden;`};

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);

  ${cssMediaMax.tablet`
    background-color: white;
  `};
`;

const ModalContentContainer = withDirection(styled(CenterAlignedColumn)`
  direction: ${props => (props.forceLTR ? 'ltr' : props.direction)};

  ${cssMediaMax.tablet`
    align-self: flex-start;
    width: 100%;
    height: 100%;
  `};
`);

class Modal extends React.Component {
  componentDidMount = () => {
    this.props.onModalOpened();
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    window.addEventListener('keyup', this.handleKeyUp, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false);
  };

  closeModal = (e) => {
    e?.stopPropagation();
    // eslint-disable-next-line
    this.props.closeCurrentModal(); // For router based modals
    if (this.props.onCloseRequested) {
      this.props.onCloseRequested(); // For non-router based modals
    }
  };

  handleKeyUp = (e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        this.closeModal(e);
        window.removeEventListener('keyup', this.handleKeyUp, false);
      },
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  };

  clickedOutsite = (e, isDesktop) => {
    if (!isDesktop) {
      return;
    }
    // eslint-disable-next-line
    const domNode = ReactDOM.findDOMNode(this.modalContainer);
    if (domNode === e.target) {
      this.closeModal(e);
    }
  };

  renderContent = () => (
    <ModalContentContainer forceLTR={this.props.forceLTR}>
      {this.props.closable ? (
        <Media query={mediaQueryMax('tablet')}>
          {matches => (matches ? (
            <TabletCloseIcon
              src={`/${imagesPathName}/AccountManagement/close-copy.png`}
              onClick={e => this.closeModal(e)}
            />
          ) : (
            <CloseIcon className="el-icon el-icon-remove" onClick={e => this.closeModal(e)} />
          ))
          }
        </Media>
      ) : (
        null
      )}
      {this.props.children}
    </ModalContentContainer>
  );

  render = () => {
    const {
      location,
      history,
      direciton,
      tabletOverflow,
      smallModalStyle,
      height,
    } = this.props;

    return (
      <div>
        <ReactModal isOpen style={styles}>
          <Media query={mediaQueryMin('desktop')}>
            {matches => (
              <ModalContainer
                ref={(ref) => {
                  this.modalContainer = ref;
                }}
                onClick={e => this.clickedOutsite(e, location, history, matches)}
                dir={direciton}
                tabletOverflow={tabletOverflow}
                smallModalStyle={smallModalStyle}
                modalHeight={height}
              >
                {this.renderContent()}
              </ModalContainer>
            )}
          </Media>
        </ReactModal>
      </div>
    );
  };
}

Modal.defaultProps = {
  closable: true,
  tabletOverflow: true,
  smallModalStyle: null,
};

Modal.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  history: PropTypes.string.isRequired,
  closeCurrentModal: PropTypes.func.isRequired,
  closable: PropTypes.bool,
  tabletOverflow: PropTypes.bool,
  smallModalStyle: PropTypes.func,
}.isRequired;

export default withRouter(withModalTracker(Modal));
