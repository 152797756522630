import React, { Fragment, Component } from 'react';
import { graphql } from 'react-relay';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import { isAuthenticated } from '~/modules/core/utils/accessManagementHelpers/userInfoUtils';
import AykIconOnlyButton from '~/modules/ecommerceCoreUI/components/basic/AykIconOnlyButton';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';

const Mutation = graphql`
  mutation WishButtonMutation($variant_id: ID!) {
    wish(variant_id: $variant_id) {
      current_user {
        ...WishData_variantInfo @relay(mask: false)
      }
      result {
        wish_listed
      }
      errors {
        field
        messages
        code
      }
    }
  }`;

const MutationRoot = 'wish';

class WishButton extends Component {
  state={
    wishListed: this.props.wishListed,
  }

  onMutationError = () => {};

  onMutationSuccess = (data) => {
    this.props.notifyAlert({
      component: () => (
        <Label inverted sm emphasized>
          <i className="el-icon" />
          &nbsp;
          {data.wish.result.wish_listed ? i18next.t('ecommerceCoreUI:Alert.wishListCardAdd') : i18next.t('ecommerceCoreUI:Alert.wishListCardRemove')}
        </Label>
      ),
      type: AlertTypes.success,
      opacity: 1,
      toastID: 'WishButton_success_toast',
    });
    this.setState({ wishListed: data.wish.result.wish_listed });
  };

  onMutationLoading = () => {};

  toggleWish = (e) => {
    // stop propagation to prevent shop card click that would open shop page
    e.stopPropagation();
    e.preventDefault();

    if (isAuthenticated()) {
      commitMutation(
        this.props.environment,
        Mutation,
        MutationRoot,
        {
          variant_id: parseInt(this.props.variantId, 10),
        },
        this.onMutationSuccess,
        this.onMutationError,
        this.onMutationLoading,
      );
    } else {
      setLocalMessageFor(PathRedirectKeysMapper.login, i18next.t('ecommerceCoreUI:Alert.wishlistLoginRequired'));
      navigateToModal(this.props.history, '/accountManagement/login');
    }
  };

  render() {
    const { isVertical } = this.props;

    return (
      <Fragment>
        <AykIconOnlyButton
          isVertical={isVertical}
          iconName={this.state.wishListed ? 'icon-wishlist-filled' : 'icon-wishlist-circle'}
          title={this.state.wishListed ? i18next.t('ecommerceCoreUI:ToolTipTrans.wishListCardRemove') : i18next.t('ecommerceCoreUI:ToolTipTrans.wishListCardAdd')}
          onClick={this.toggleWish}
        />
      </Fragment>
    );
  }
}

WishButton.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  wishListed: PropTypes.bool,
  isVertical: PropTypes.bool,
  variantId: PropTypes.string.isRequired,
  notifyAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

WishButton.defaultProps = {
  isVertical: true,
  wishListed: false,
};

export default withAlertMsg(withRouter(
  withRelayEnvironment(
    WishButton,
  ),
));
