export default {
  apiKey: 'AIzaSyASlr5nXNji0_KDnwmdX1qcXx5nbdTTwcs',
  authDomain: 'ayk-mall-xyz.firebaseapp.com',
  databaseURL: 'https://ayk-mall-xyz-default-rtdb.firebaseio.com',
  projectId: 'ayk-mall-xyz',
  storageBucket: 'ayk-mall-xyz.appspot.com',
  messagingSenderId: '1067178200051',
  appId: '1:1067178200051:web:c6b18ae09409f420412086',
  measurementId: 'G-K5SD74GDBP',
};
