import React from 'react';
import PropTypes from 'prop-types';
import { Trans, translate } from 'react-i18next';
import styled, { css } from 'react-emotion';
import { XXXLargeSpacer, XLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { BasicButton } from '~/modules/coreUI/components/legacies/Button';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Icon from '~/modules/coreUI/components/basic/Icon';
import {
  loadPrev, loadNext, leftClassName, rightClassName,
} from './PaginationNextAndPrevious';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const Left = withDirection(styled.div`
  position: absolute;
  ${props => (props.direction === 'rtl'
    ? css`
      right: ${props.theme.paddings.large}px;
      transform: rotate(180deg);
      `
    : css`
      left: ${props.theme.paddings.large}px;
  `)}
`);

const Right = withDirection(styled.div`
  position: absolute;
  ${props => (props.direction === 'rtl'
    ? css`
      left: ${props.theme.paddings.large}px;
      transform: rotate(180deg);
      `
    : css`
      right: ${props.theme.paddings.large}px;
  `)}
`);

const PaginationBoxResponsive = ({ paginator, pageInfo }) => (
  <React.Fragment>
    <XLargeSpacer />
    <Row width="100%" spaceBetweenJustified>
      <BasicButton block primary disabled={pageInfo.current_page === 1} onClicked={() => loadPrev(paginator, pageInfo)}>
        <Left>
          <Icon className={leftClassName} />
        </Left>
        <Trans i18nKey="Pagination.PreviousButton" />
      </BasicButton>
      <XXXLargeSpacer />
      <BasicButton block primary disabled={pageInfo.current_page === Math.ceil(pageInfo.items_count / pageInfo.limit)} onClicked={() => loadNext(paginator, pageInfo)}>
        <Right>
          <Icon className={rightClassName} />
        </Right>
        <Trans i18nKey="Pagination.NextButton" />
      </BasicButton>
    </Row>
  </React.Fragment>
);

PaginationBoxResponsive.propTypes = {
  pageInfo: PropTypes.shape({}),
  paginator: PropTypes.shape({}.isRequired),
}.isRequired;

export default translate('ecommerceCoreUI')(PaginationBoxResponsive);
