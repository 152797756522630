/* eslint-disable camelcase */
/* eslint-env browser */
/* eslint-disable */
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import SeoConfigurationsQuery from './SeoConfigurationsQuery';

import getPageTitle from '~/pageTitle';


const SeoConfigurations = ({ queryResult }) => {
  setTimeout(() => {
    localStorage.setItem("seoAlt", queryResult?.seo_configurations.alternate_name);
    localStorage.setItem("contentHeader", queryResult?.seo_configurations.content_header);
  }, 5000);
  return (
    <Helmet>
      { getPageTitle() && (
        <title>{getPageTitle()}</title>
      )}
      { !getPageTitle() && (
        <title>{queryResult?.seo_configurations.title}</title>
      )}
      <meta name="description" content={queryResult?.seo_configurations.meta_description} />
      <meta name="keywords" content={queryResult?.seo_configurations.meta_keywords} />
      {!queryResult&&(
         <meta property="og:title" content="Ayk Mall" />
      )}
      {!queryResult&&(
         <meta property="og:description" content="Online Shopping Mall" />
      )}
    
    </Helmet>
  );
};


SeoConfigurations.defaultProps = {
  queryResult: null,
};
SeoConfigurations.propTypes = {
  queryResult: PropTypes.shape({}),
};

export default withRootQuery(
  SeoConfigurations,
  SeoConfigurationsQuery,
  SeoConfigurations,
  null,
  { force: true },
);
