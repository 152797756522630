/**
 * @flow
 * @relayHash 6853ff39dcf89da2744caea0da5d2d52
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AvailableSocialMedia = "apple" | "facebook" | "google" | "instagram" | "twitter" | "%future added value";
export type SocialMediaSignInMutationVariables = {|
  token: string,
  secret?: ?string,
  email?: ?string,
  pid?: ?string,
  provider: AvailableSocialMedia,
|};
export type SocialMediaSignInMutationResponse = {|
  +socialmedia_signin: ?{|
    +token: ?string,
    +client_id: ?string,
    +expiry: ?string,
    +found_email: ?string,
    +user: ?{|
      +id: string,
      +ref_id: string,
      +first_name: ?string,
      +last_name: ?string,
      +email: ?string,
      +verified: ?boolean,
      +avatar: ?string,
      +default_lang: ?string,
      +default_currency: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +code: ?number,
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
    |}>,
  |}
|};
export type SocialMediaSignInMutation = {|
  variables: SocialMediaSignInMutationVariables,
  response: SocialMediaSignInMutationResponse,
|};
*/


/*
mutation SocialMediaSignInMutation(
  $token: String!
  $secret: String
  $email: String
  $pid: String
  $provider: AvailableSocialMedia!
) {
  socialmedia_signin(token: $token, secret: $secret, email: $email, pid: $pid, provider: $provider) {
    token
    client_id
    expiry
    found_email
    user {
      id
      ref_id
      first_name
      last_name
      email
      verified
      avatar
      default_lang
      default_currency
    }
    errors {
      code
      field
      messages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "secret",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pid",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "provider",
    "type": "AvailableSocialMedia!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "socialmedia_signin",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "pid",
        "variableName": "pid",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider",
        "type": "AvailableSocialMedia!"
      },
      {
        "kind": "Variable",
        "name": "secret",
        "variableName": "secret",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token",
        "type": "String"
      }
    ],
    "concreteType": "SocialMediaSigninResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "client_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expiry",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "found_email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ref_id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "first_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verified",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_lang",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_currency",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SocialMediaSignInMutation",
  "id": null,
  "text": "mutation SocialMediaSignInMutation(\n  $token: String!\n  $secret: String\n  $email: String\n  $pid: String\n  $provider: AvailableSocialMedia!\n) {\n  socialmedia_signin(token: $token, secret: $secret, email: $email, pid: $pid, provider: $provider) {\n    token\n    client_id\n    expiry\n    found_email\n    user {\n      id\n      ref_id\n      first_name\n      last_name\n      email\n      verified\n      avatar\n      default_lang\n      default_currency\n    }\n    errors {\n      code\n      field\n      messages\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SocialMediaSignInMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SocialMediaSignInMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff8cc330a7e02d3cc4744b3f69edd6b7';
module.exports = node;
